import config from "@/config"
import http from "@/utils/request"

export default{
   
    add:{
		url: `${config.API_URL}/exportRecord/`,
		name: "新增导出记录",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    list:{
		url: `${config.API_URL}/exportRecord/`,
		name: "查询导出记录",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
    delete: {
		url: `${config.API_URL}/exportRecord/`,
		name: "删除导出记录",
		delete: async function(id,data){
			return await http.delete(this.url+id+"/", data);
		}
	},

}