import config from "@/config"
import http from "@/utils/request"

export default {
	add: {
		url: `${config.API_URL}/batchBind/`,
		name: "新增批量绑定场所",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    detail: {
		url: `${config.API_URL}/batchBindDeviceLog/`,
		name: "查询批量绑定详情",
		get: async function(id){
			return await http.get(this.url+id+"/");
		}
	},
    list: {
		url: `${config.API_URL}/batchBindDeviceLog/`,
		name: "查询批量绑定列表",
		get: async function(data){
			return await http.get(this.url, data);
		}
	}
}
