import config from "@/config"
import http from "@/utils/request"
// 检查图片
export default{
    add:{
		url: `${config.API_URL}/dangerPhoto/`,
		name: "新增检查图片",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    list:{
		url: `${config.API_URL}/dangerPhoto/`,
		name: "查询检查图片",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
}