// 摄像头版本
import config from '@/config';
import http from '@/utils/request';

export default {
    searchCameraVersionsList: {
        url: `${config.API_URL}/qxCamera/version/list`,
        name: "查询摄像头版本",
        get: async function (){ 
            return await http.get(this.url);
        }    
    },
    addCameraVersions: {
        url: `${config.API_URL}/qxCamera/version/add`,
        name: "添加摄像头版本",
        post: async function (data){
            /**
             * name      string     版本名称（必需）
             * modelId   integer    型号ID（必需）
             * Vfile     file       版本文件（必需）
             */
            return await http.post(this.url, data);
        }
    },
    // 通过版本ID删除版本
    deleteCameraVersions: {
        url: `${config.API_URL}/qxCamera/version/delete`,
        name: "删除摄像头版本",
        post: async function ( versionId ){
           
            return await http.post(this.url, versionId);
        }    
    },
    // 修改版本
    updateCameraVersion: {
        url: `${config.API_URL}/qxCamera/version/update`,
        name: "修改版本",
        patch: async function(data){
            return await http.patch(this.url, data);
        }
    }
}