import config from "@/config"
import http from "@/utils/request"

export default{
    province:{
		url: `${config.API_URL}/province`,
		name: "省信息",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
    city:{
		url: `${config.API_URL}/city/`,
		name: "市区信息",
		get: async function(id,data){
			return await http.get(this.url+id+"/", data);
		}
	},
    county:{
		url: `${config.API_URL}/county/`,
		name: "县区信息",
		get: async function(id,data){
			return await http.get(this.url+id+"/", data);
		}
	},
	alarmList:{
		url: `${config.API_URL}/alarmList/`,
		name: "报警信息",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
	basicNews:{
		url: `${config.API_URL}/device/`,
		name: "设备基本信息",
		get: async function(id){
			return await http.get(this.url+id+"/");
		}
	},
	alarmData:{
		url: `${config.API_URL}/deviceAttributeLatest`,
		name: "报警设备数据",
		get: async function(data){
			return await http.get(this.url,data);
		}
	},
	HistoryRecord:{
		url: `${config.API_URL}/deviceAlarm/`,
		name: "设备基本信息",
		get: async function(id,data){
			return await http.get(this.url+id+"/",data);
		}
	},
	configAlarm:{
		url: `${config.API_URL}/deviceAlarm/`,
		name: "设备基本信息",
		patch: async function(id,data){
			return await http.patch(this.url+id+"/",data);
		}
	},
	getPosition:{
		url: `${config.API_URL}/siteRegion`,
		name: "设备基本信息",
		get: async function(data){
			return await http.get(this.url,data);
		}
	},
	getDeviceTotal:{
		url: `${config.API_URL}/newDeviceCount/`,
		name: "设备总数",
		get: async function(data){
			return await http.get(this.url,data);
		}
	},
	getAlarmNum:{
		url: `${config.API_URL}/todayAlarm`,
		name: "报警次数",
		get: async function(data){
			return await http.get(this.url,data);
		}
	}

}