import config from "@/config"
import http from "@/utils/request"

export default {
	add: {
		url: `${config.API_URL}/point/`,
		name: "添加点位",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    delete: {
		url: `${config.API_URL}/point/`,
		name: "删除点位",
		delete: async function(id,data){
			return await http.delete(this.url+id+"/", data);
		}
	},
    detail: {
		url: `${config.API_URL}/point/`,
		name: "查询点位详情",
		get: async function(id,data){
			return await http.get(this.url+id+"/", data);
		}
	},
    list: {
		url: `${config.API_URL}/point/`,
		name: "查询点位列表",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
    update: {
		url: `${config.API_URL}/point/`,
		name: "修改点位",
		patch: async function(id,data){
			return await http.patch(this.url+id+"/", data);
		}
	},
    select:{
        url: `${config.API_URL}/searchPoint/`,
		name: "下拉查询点位列表",
		get: async function(data){
			if(data!=null&&data!=""){
				return await http.get(this.url+data+"/");
			}else{
				return await http.get(this.url);
			}
		}
    },
    imageList:{
        url: `${config.API_URL}/pointImage/`,
		name: "查看点位图片",
		get: async function(id,data){
			return await http.get(this.url+id+'/',data);
		}
    },
    addImage:{
        url: `${config.API_URL}/pointImage/`,
		name: "添加点位图片",
		post: async function(id,data){
			return await http.post(this.url+id+'/',data);
		}
    },
    deleteImage:{
        url: `${config.API_URL}/pointImage/`,
		name: "删除点位图片",
		delete: async function(id,data){
			return await http.delete(this.url+id+'/',data);
		}
    }
}
