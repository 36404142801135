import config from "@/config"
import http from "@/utils/request"

export default {
	add: {
		url: `${config.API_URL}/deviceRules/`,
		name: "添加设备联动规则",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    delete: {
		url: `${config.API_URL}/deviceRules/`,
		name: "删除设备联动规则",
		delete: async function(id,data){
			return await http.delete(this.url+id+"/", data);
		}
	},
    detail: {
		url: `${config.API_URL}/deviceRules/`,
		name: "查询设备联动规则详情",
		get: async function(id,data){
			return await http.get(this.url+id+"/", data);
		}
	},
    list: {
		url: `${config.API_URL}/deviceRules/`,
		name: "查询设备联动规则列表",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
    update: {
		url: `${config.API_URL}/deviceRules/`,
		name: "修改设备联动规则",
		patch: async function(id,data){
			return await http.patch(this.url+id+"/", data);
		}
	},
}
