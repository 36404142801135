import config from "@/config"
import http from "@/utils/request"
export default {
    device:{
		url: `${config.API_URL}/exportAllDeviceData`,
		name: "导出所有设备数据",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    alarm:{
		url: `${config.API_URL}/exportAllNewAlarmData`,
		name: "导出所有报警数据",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    fault:{
		url: `${config.API_URL}/exportAllFaultData`,
		name: "导出所有故障数据",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    
}