import config from "@/config"
const tool = {
    /* 日期格式化 */
    dateFormat(date, fmt = 'yyyy-MM-dd') {
        if (date == null) {
            date = new Date()
        } else {
            date = new Date(date)
        }

        var o = {
            "M+": date.getMonth() + 1,                 //月份
            "d+": date.getDate(),                    //日
            "h+": date.getHours(),                   //小时
            "m+": date.getMinutes(),                 //分
            "s+": date.getSeconds(),                 //秒
            "q+": Math.floor((date.getMonth() + 3) / 3), //季度
            "S": date.getMilliseconds()             //毫秒
        };
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        }
        for (var k in o) {
            if (new RegExp("(" + k + ")").test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
            }
        }
        return fmt;
    },
    getMediaUrl(url) {
        return `${config.STATIC_URL}` + url
    },
    download(url) {
        window.location.href = this.getMediaUrl(url)
    },

    // 去重 [{}, {}, {}, {}, {}] 格式
    removeDuplicates(array, field) {
        const seen = {}; // 创建一个空对象来跟踪已经遇到的字段值
        const result = [];
        array.forEach(item => {
            const value = item[field]; // 获取对象的字段值
            if (!seen[value]) { // 如果该值尚未在跟踪对象中出现
                seen[value] = true; // 将其添加到跟踪对象中
                result.push(item); // 将对象添加到结果数组中
            }
        });

        return result;
    },
}
export default tool
