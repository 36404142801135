
import Vue from 'vue'
import SvgIcon from '@/components/SvgIcon/SvgIcon.vue'// svg component
 
// register globally
Vue.component('svg-icon', SvgIcon)
 
const req = require.context('@/assets/icons/svg', false, /\.svg$/)
const requireAll = requireContext => {
    requireContext.keys().map(requireContext)
    // console.log(requireContext.keys().map(requireContext),"查看");
}

requireAll(req)