<template>
    <div class="home-menu-left" >
        <el-menu 
            :default-active="setIndex($route)"
            class="el-menu-vertical-demo"
            :background-color="localSet.menuBg"
            :text-color="localSet.menuTC"
            :active-text-color="localSet.menuATC"
            @open="handleOpen"
            @close="handleClose"
            :collapse="isCollapse"
            @select="gotoChile"
            :unique-opened="true"
            >
            <div class="menu-logo-bigbox" :style="{'background-color': localSet.menuBg}">
                <div class="menu-logo">
                    <!-- :src="getURL(entityLogo)" -->
                    <el-image class="menu-logo-img" :src="getURL(entityLogo)" fit="scale-down"  style="width: 8vw;height: 6vh;" v-if="entityLogo"></el-image>
                    <div  v-else>
                        <div class="title"  v-if="langther == 'zh'" style="font-size: 16px; color: #3590f8;">
                            {{ entityName }}
                        </div>
                        <div class="title"  v-else style="font-size: 12px; color: #3590f8;">
                            {{ entityName }}
                        </div>
                    </div>
                    <div class="title fl_all_cent" :style="changeSIZE(bigTitle)" v-if="langther == 'zh'" >
                        {{ bigTitle }}
                    </div>
                    <div class="title fl_all_cent" :style="changeSIZE(bigTitle)" v-else style="font-size:16px">
                        {{ bigTitle }}
                    </div>
                    
                </div>
            </div>
            <template v-for="(cur, index) in routes">
                <el-submenu  :index="cur.path" v-if="cur.children && cur.children.length > 0" :key="cur.path">
                    <template slot="title">
                        <i class="iconfont" :class="cur.Meta.icon" :style="{color: menuTC}"></i>
                        <span>{{ cur.Meta.title }}</span>
                    </template>
                    <childrenMenu :chridMenuList="cur.children" :localSet="localSet"></childrenMenu>
                </el-submenu>
                <el-menu-item v-else :index="cur.path" :key="index">
                    <i class="iconfont" :class="cur.Meta.icon" :style="{color: menuTC}"></i>
                    {{ cur.Meta.title }}
                </el-menu-item>
            
            </template>
        </el-menu>
    </div>
</template>
<script>
import {mapState ,mapMutations} from 'vuex';

import childrenMenu from "@/components/layout/menu/chridMenu.vue"
export default{
    name:'menuLeft',
    data(){
        return{
            // isCollapse: this.$store.state.isCollapse,
            routes:[],
            bigTitle:'',
            entityLogo:'',
            entityName:"",
            newPath:'',
            langther:''
        }
    },
    components:{
        childrenMenu
    },
    computed:{
        ...mapState(['isCollapse']),
        ...mapMutations(['changeMenu']),
        localSet() {
            let obj = this.$store.state.localSet;
            if (obj.id === undefined || obj.id === null) {
                let data = JSON.parse(localStorage.getItem("localSet"));
                this.$store.commit("setLocalSet", data)
            }
            // 
            return this.$store.state.localSet;
        },
        menuTC() {
            return this.localSet.menuTC?this.localSet.menuTC:"#a0a5bd";
        },
        HMC() {
            return this.localSet.HMC;
        },
        ATC() {
            return this.localSet.menuATC?this.localSet.menuATC:"#409EFF";
        },
        HTC() {
            return this.localSet.HTC?this.localSet.HTC:"#fff";
        },
        MABC() {
            return this.localSet.MABC?this.localSet.MABC:"rgba(15, 87, 222, .4)";
        },
    },
    created(){
    },
    mounted(){
        this.langther = localStorage.getItem('theLanguage')
        setTimeout(() => {
            this.bigTitle = JSON.parse(localStorage.getItem("AllTitle")).platform_title
            this.entityName = JSON.parse(localStorage.getItem("AllTitle")).entity_name
        }, 400);
        let routes = JSON.parse(localStorage.getItem("menu"))     //使用JSON,拿到数据并使用
        let userInfo = JSON.parse(localStorage.getItem("AllTitle"))
        if (userInfo.role_id !== 1 && userInfo.is_salesman === false) {
            
            let index = -1;
            for (let i = routes.length - 1; i >= 0; i--) {
                if (routes[i].name === "payManage") {
                    index = i;
                    break
                }
            }
            console.log("index", index)
            if (index !== -1) {
                let children = routes[index].children;
                if (children && children.length > 0) {
                    for (let i = 0; i < children.length; i++) {
                        if (children[i].name === 'paymentPackge') {
                            children.splice(i, 1)
                            break;
                        }
                    }
                }
            }
            
        }
        this.routes = routes;
        this.bigTitle = ''
        this.entityLogo = JSON.parse(localStorage.getItem("AllTitle")).logo
        this.entityName = '';
    },
    watch:{
        localSet: {
            handler(nv) { 
                console.log(nv, "localSet", this.localSet.HMC)
            },
            deep: true,
        },
    },
    updated(){
    },
    methods:{
        ...mapMutations(['delUserInfo','delToken','delMenu']),
            /* eslint-disable */
        handleOpen(key, keyPath) {
            // console.log(key, keyPath);
        },
        /* eslint-disable */
        handleClose(key, keyPath) {
            // console.log(key, keyPath);
        },
        setIndex(obj){
            return obj.path
            // return obj.path+","+obj.name+","+obj.Meta.title+","+obj.Meta.icon
        },
        /* eslint-disable */
        async gotoChile(key, keyPath) {
            let that = this
            this.$emit('getMenuName', key.split(",")[1]);
            let ListMenu = JSON.parse(localStorage.getItem("menu"));
            await this.getMeta(ListMenu, key);
            // this.$router.push(key)
            this.$router.replace({
                path: that.newPath[0]
            });
            this.$store.commit("selectMenu", that.newPath);
        },
        // 拼接服务器URL
        getURL(url){
            return this.$TOOL.getMediaUrl(url)
        },
        changeSIZE(val){
            if(val.length  >= 14 && val.length <= 16 ){
                return `font-size:20px`
            }else if(val.length  >= 16 && val.length <= 18 ){
                return `font-size:18px`
            }else if(val.length  >= 18 && val.length <= 20){
                return `font-size:17px`
            }
        },

        // 拿到meta数据
        async getMeta(list,title){
            await list.forEach( item=>{
               if(item.path == title){
                    this.newPath =[
                        item.path,
                        item.name,
                        item.Meta.title,
                        item.Meta.icon
                    ]
                    return 
               }else{
                if(item.children){
                    // 有子
                    this.getMeta(item.children,title)
                }
               }
            })
        },
      
    }
}

</script>
<style lang="scss" scoped>
@import '@/assets/scss/menu/menu.scss';
@import '@/assets/scss/flex/flex-all';
// @import '@/assets/scss/elemtntChange/menuLaout.scss';
// @import '@/assets/scss/PublicStyle/view.scss'
::-webkit-scrollbar {
    width: 0px;
    height: 10px;
    background-color:rgb(240,242,245);
}
// 鼠标放上去后 背景颜色修改和文字颜色修改
// 鼠标移入 时样式
::v-deep .el-submenu__title:hover, .el-menu-item:hover {
    color: v-bind(HTC) !important;
    background: v-bind(HMC) !important; 
    i {
        color: v-bind(HTC) !important
    }
}

// 选中后 menu的背景颜色 文字颜色 icon颜色 修改  激活后样式
.el-menu-item.is-active {
    color: v-bind(ATC) !important;
    background-color: v-bind(MABC) !important;
    i {
        color: v-bind(ATC) !important;
    }
}
::v-deep .el-menu-item.is-active:hover{
    color: v-bind(HTC) !important;
    background-color: v-bind(HMC) !important;
    background-image: none !important;
    i {
        color: v-bind(HTC) !important
    }
}
::v-deep .el-submenu__title:hover i,
.el-menu-item:hover i {
    // background: v-bind(HMC) !important;
    color: v-bind(HMC);
}
</style>