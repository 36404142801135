
const other = {
    state:{
        mqttAlarmClear:1,
        mqttDataArr: []
    },
    mutations:{
        mqttIsHandle(state){
            state.mqttAlarmClear++
        },
        mqttDataArr(state, value){
            state.mqttDataArr.push = value;
        }

    },
    actions:{

    },
    getter:{
        getMqttData(state){
            return state.mqttDataArr
        }
    }
}

export default other