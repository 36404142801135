import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/language'
import routes from './router'
import messageAll from '@/public_Js/messageAll'
import store from '@/store/index'
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
Vue.use(VueRouter)


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
//保存原型对象的Push
let originPush = VueRouter.prototype.push
let originReplace = VueRouter.prototype.replace
//重写push方法
VueRouter.prototype.push = function (location, res, rej) {
    if (res && rej) {
        originPush.call(this, location, res, rej)
    }
    else {
        originPush.call(this, location, () => { }, () => { })
    }
}
//重写replace方法
VueRouter.prototype.replace = function (location, res, rej) {
    if (res && rej) {
        originReplace.call(this, location, res, rej)
    }
    else {
        originReplace.call(this, location, () => { }, () => { })
    }
}

router.beforeEach(async (to, from, next) => {
    nprogress.start()
    let token = localStorage.getItem('token')

    if (to.path === "/login") {
        if (token) {
            next({
                path: '/ApplicationCenter'
            })
            return
        } else {
            next();
            nprogress.done()
        }
        return false;
    }


    if (to.path === "/login") {
        if (token) {
            next({
                path: '/ApplicationCenter'
            })
            return
        }
        next();
        nprogress.done()
        return false;
    }
    let loginHref = location.href;
    // 没有token 跳转到login
    if (token == undefined || token == "" || token == null) {
        if (loginHref.search("username") != -1 && (loginHref.search("password") != -1)) {
            let nameReg = /(?<=username=)[^&]+/;
            let pwdReg = /(?<=password=)[^&]+/;
            let uname = loginHref.match(nameReg)[0];
            let pwd = loginHref.match(pwdReg)[0];
            console.log(uname, pwd)
            let flag = await successVCode(uname, pwd);
            if (!flag) {
                next({
                    path: '/login'
                });
            } else {
                next({
                    path: '/ApplicationCenter'
                });
            }
            nprogress.done()
            return;
        }

        next({
            path: '/login'
        });
        nprogress.done()
        return;
    }

    if (
        loginHref.search("username") != -1 && (loginHref.search("password") != -1) && token
    ) {
        let nameReg = /(?<=username=)[^&]+/;
        let pwdReg = /(?<=password=)[^&]+/;
        let uname = loginHref.match(nameReg)[0];
        let pwd = loginHref.match(pwdReg)[0];
        console.log(uname, pwd)
        let flag = await successVCode(uname, pwd);
        if (!flag) {
            next({
                path: '/login'
            });
        } else {
            let href = loginHref.split('?')[0]
            location.href = href + "ApplicationCenter";
        }
        nprogress.done()
        return;
    }
    // 暗黑模式是否开启
    if (store.state.theme.isDarkness) {
        let html = document.documentElement
        // 进入不需要暗黑模式的页面
        if (to.meta.dark != undefined && !to.meta.dark) {
            html.classList.remove('dark')
        } else {
            html.classList.add('dark')
        }
    }
    
    // 跳转生产
    let role = store.state.userRole
    if (!role) {
        role = JSON.parse(localStorage.getItem("AllTitle")).role_id
    }
    if (to.fullPath == "/ProductionSystem") {
        if (to.meta.role[0] != role && to.meta.role[1] != role) {
            messageAll.warningOpen("您没有权限查看!")
            nprogress.done()
            return next('/ApplicationCenter')
        }
    }

    // 解析路由
    if (store.state.getAsideList) {   //close必须let唉router.beforeEach外面,用于解决一直循环
        if (localStorage.getItem('theLanguage') == 'undefined') {
            localStorage.setItem('theLanguage', 'zh')
        }
        // refeRouter()

        //判断是否加载路由
        //接口拿到后端的路由,从原型加载请求文件的方法(一般是登录的时候将路由存到localStore中,然后进行调用)
        let cese = await Vue.prototype.$API.account_basic.loginNews.get({
            language: localStorage.getItem('theLanguage')
        })
        if (!cese) return;
        
        //后端menu数据赋值 
        let menuList = cese.data.menu;
        // 获取路由文件的路由对象
        let allRouter = router.options.routes;

        // 开始正式处理 后端menu数据, 该遍历会丢失第一个元素
        menuList.forEach(async v => {
            // v.children 是接收每个二级路由返回的数组
            v.children = routerChildren(v.children);
            //因为后端数据,component有数据就是第三级路由
            if (v.component) {
                v.component = routerComp(v.component);
            } else {
                //  没有component 的第二级路由
                v.component = { render: (e) => e("router-view") }
            }
        })

        // 完整路由,用于给动态添加进去
        allRouter[routes.length - 1].children.push(...menuList)
        //  清空路由缓存(因为我这里跳转都要调用addRoutes,所以我要先清空路由,防止路由重复)
        router.matcher = new VueRouter().matcher;
        //  重新赋值路由(addRoutes会重复添加路由,即使已经存在,虽然可以运行,但是F12会有报错,需要router.Matcher解决)
        router.addRoutes(allRouter)

        let adminhome = {
            name: "adminHome",
            path: '/adminHome',
            component: '/adminHome/newHome/newHoem.vue',
            Meta: {
                title: i18n.t('basic.menubar.backstageHomePaee'),
                icon: 'icon-shouye',
            },
        }
        cese.data.menu.unshift(adminhome)
        // console.log("cese.data.role_id !== 1 && cese.data.is_salesman === false", cese.data.role_id !== 1 && cese.data.is_salesman === false)
        // if (cese.data.role_id !== 1 && cese.data.is_salesman === false) {
        //     let data = cese.data.menu;
        //     let index = -1;
        //     for (let i = data.length - 1; i >= 0; i--) {
        //         if (data[i].name === "payManage") {
        //             index = i;
        //             break
        //         }
        //     }
        //     console.log("index", index)
        //     let children = data[index].children;
        //     if (children && children.length > 0) {
        //         for (let i = 0; i < children.length; i++) {
        //             if (children[i].name === 'paymentPackge') {
        //                 children.splice(i, 1)
        //                 break;
        //             }
        //         }
        //     }
        // }
        // console.log("case", cese.data.menu)

        //  这是系统自带的标签,
        //将 后端新路由 传给vuex中重新给localstore赋值 
        store.commit('setMenu', cese.data.menu)
        // adminhome给vuex
        store.commit('getAdminHome', adminhome)
        //  close是为了防止陷入循环,上面讲过
        store.commit('asideList')

        next({
            ...to,
            replace: true
        })
    }
    next();

});


// 给路由添加路由路径以及路由懒加载的方法 comp：路由路径
function routerComp(comp) {
    // 路由中component的路径
    return (resolve) => require([`@/views/${comp}`], resolve)    //懒加载
    // return () => import(`@/views/${comp}`)    //懒加载
    //  return require(`@/views/${comp}`).default   //非懒加载
}

// 子路由解析 children: 获取后端接口后的每个路由的二级路由
function routerChildren(children) {
    // console.log(children);
    if (children == undefined) return
    // 便利二级路由
    children.forEach(v => {
        // 没有子路由,直接component赋值
        v.component = routerComp(v.component);
        if (v.children) {
            // 有子路由就递归
            v.children = routerChildren(v.children)
        }
        v.Meta.keepalive = true;
    })
    // 返回
    return children
}

async function successVCode(uname, pwd) {
    // 登录接口
    let language = localStorage.getItem('theLanguage');
    let login = await Vue.prototype.$API.login.login.post({
        loginname: uname,
        passwor: pwd,
        language
    });
    // 登录成功之后返回路由 
    if (!login) {
        messageAll.errorOpen(i18n.t("basic.newLogin.loginLose"));
        return false;
    }
    if (login.data.code == 200) {
        messageAll.successOpen(i18n.t("basic.newLogin.loginSucc"))
        // // 把 token 传到 vuex, vuex 会进行本地存储持久化
        store.commit("setToken", login.headers["x-auth-token"]);
        // // 将大标题名字都带上去
        let title = await Vue.prototype.$API.account_basic.loginNews.get({ language })
        login.data.data.menu.unshift(store.state.admingHome)
        store.commit('getAllTitle', title.data)

        // // 存储用户名 主页会用到用户名称
        store.commit("setLoginName", uname);
        store.commit("setUserRoleId", login.data.data.role);
        // // 先把 路由 存储好后续跳转会用到
        store.state.menu = login.data.data.menu
        // // 存入到本地

        if (title.data.platform_title == '') {
            title.data.platform_title = i18n.t("basic.newLogin.platformTitle") //"安防后台管理"
        }
        if (title.data.entity_name == '') {
            title.data.entity_name = i18n.t("basic.newLogin.title") //"智慧安防云平台"
        }
        store.commit('setMenu', login.data.data.menu);

        // 登录成功的MQTT
        let userName = localStorage.getItem("loginName")
        if (userName != "") {
            store.state.worker.postMessage({ userName })
        }
        // this.$router.push({ name: 'ApplicationCenter' })
        return true
    } else if (login.data.code == 202) {
        // this.$router.push({ name: 'ApplicationCenter' })
        return true
    } else if (login.data.code == 400) {
        messageAll.errorOpen(i18n.t("basic.newLogin.loginLose"))
        return false;
    }
}

router.afterEach(() => {
    nprogress.done()
})

export default router
