<template>
    <div class="header_menu-news">
        <div class="personal_news">
          
            <el-dropdown placement="bottom" @command="changeLangeuage">
                <div style="display:flex;justify-content:center"><i class="iconfont icon-yuyan-02" style="margin:0 6px;cursor:pointer"></i></div>

                <el-dropdown-menu slot="dropdown" style="width: 100px;margin: 10px 0;">
                    <el-dropdown-item command="zh" style="padding-left: 10px;">简体中文</el-dropdown-item>
                    <el-dropdown-item command="en" style="padding-left: 10px;">English</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <el-tooltip class="item" :effect="this.$CONFIG.THEME=='light'?'dark':'light'" :content='$t("basic.menubar.workbench")' placement="top" >
                <div @click="changeRouter(1)" style="display:flex;justify-content:center"><i class="iconfont icon-gongzuotai newtest" style="margin:0 6px;cursor:pointer"></i></div>
            </el-tooltip>
            <el-tooltip class="item" :effect="this.$CONFIG.THEME=='light'?'dark':'light'" content='样式设置' placement="top" >
                <div @click="changeRouter(4)" style="display:flex;justify-content:center"><i class="iconfont icon-yifu newtest" style="margin:0 6px;cursor:pointer"></i></div>
            </el-tooltip>
            <el-tooltip class="item" :effect="this.$CONFIG.THEME=='light'?'dark':'light'" :content='$t("basic.menubar.moreHelp")' placement="top">
                <div @click="changeRouter(3)"><i class="iconfont icon-wenhao-xianxingyuankuang newtest" style="margin:0 6px;cursor:pointer"></i></div>
            </el-tooltip>
            <el-tooltip class="item" :effect="this.$CONFIG.THEME=='light'?'dark':'light'" :content='$t("basic.menubar.backstageHomePaee")' placement="top">
                <div @click="changeRouter(2)"><i class="iconfont icon-shouye1  newtest" style="margin:0 6px;cursor:pointer;margin-right:15px"></i></div>
            </el-tooltip>
            
            <div class="image fl_align_cen">
                <i class="iconfont icon-touxiang" style="font-size: 25px;"></i>
                <!-- <el-image :src="src" style="width:100%;height: 100%;border-radius: 50%;"></el-image> -->

            </div>
            <div class="name">
                    {{ userName }}
            </div>
            <div >
                 <el-popconfirm
                    :confirm-button-text='this.$t("basic.public.confirmNospace")'
                    :cancel-button-text='$t("basic.menubar.cancel")'
                    icon="el-icon-info"
                    icon-color="red"
                    :title='$t("basic.menubar.logOut")'
                    @confirm="logOout"
                >
                    <el-button type="danger" icon="el-icon-switch-button" circle slot="reference" style="width: 40px;height: 40px;display: flex;justify-content: center;align-items: center;"></el-button>
                </el-popconfirm>
            </div>
           
            
            
        </div>
        <!-- <div class="personal_news_hover">

        </div> -->
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default{
    data(){
        return{
            routes:[],
            isShowHeader:false,
            test:[],
            breadList: [
                {
                    title:this.$t("basic.menubar.backstageManage"),
                    icon:'iconfont icon-houtaiguanli',
                    name:'home',
                }
            ],
            testShow:false,
            src: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            userName:'',
            // userName:this.$store.state.
            ListMenu:'',
        }
    },
    mounted(){
        this.userName = JSON.parse(localStorage.getItem("AllTitle")).user_name

    },
    methods:{
        ...mapMutations(['delUserInfo','delToken','delMenu']),
    // 切换语言
        changeLangeuage(val){
            localStorage.setItem("theLanguage",val)
            this.$router.go(0)
        },
        changeRouter(num){
                if(num == 1){
                    this.$router.push({name:'ApplicationCenter'})
                }else if(num == 2){
                    this.$router.push({name:'adminHome'})
                }else if(num ==3){
                    const w = window.open("about:blank");
                    w.location.href = 'https://www.yuque.com/yiguangjun/iu554o?#'
                }else if(num==4){
                    this.$store.commit('openDrwer')
                }
            },
        //退出登录
        logOout(){
            if(this.$CONFIG.MQTT_CLINET !=null &&this.$CONFIG.MQTT_CLINET.connected){
                // console.log(this.mtopic,'=====',this.$TOOL.data.get('user').userInfo.userName);
                let topic = 'device/alarm/' + localStorage.getItem("loginName")
                    this.$CONFIG.MQTT_CLINET.unsubscribe(topic,err=>{
                    console.log("取消订阅成功",err);
                })
            }
            this.delUserInfo()
            this.delToken()
            this.delMenu()
            this.delCookie();//调用 
            this.$router.push({name:'login'})
        },
        delCookie() {
            document.cookie = "sessionId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        },

    },
   
}

</script>

<style lang='scss' scope>
 .header_menu-news{
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    .personal_news{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: end;
        padding: 0 5px;
        box-sizing: border-box;
        // &:hover{
        //     @include background_color('bg-user-hover');
        //     cursor: pointer;
        // }
        // &:hover  .hover_user{
        //     display: block;
        // }
        .image{
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-right: 8px;
        }
        .name{
            width: 100px;
            font-size: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: flex;
            align-items: center;
            height: 25px;
            // @include fc_important("bread-font-color");
        }

        i{
            // margin-right: 25px;
            font-size: 28px;
            // @include fc_important("bread-font-color");
        }

        .item{
            i:hover{
                // @include fc_important("bread-font-hover-color");
            }
        }
        
    }
}
</style>