import config from "@/config"
import http from "@/utils/request"

export default {
	play: {
		url: `${config.API_URL}/cameraPlay/`,
		name: "获取播放地址",
		get: async function(id,data,config){
			return await http.get(this.url+id+"/", data,config);
		}
	},
	cameraPlay: {
		url: `${config.API_URL}/cameraPlay/`,
		name: "获取播放地址",
		post: async function( data ){
			return await http.post(this.url , data);
		}
	},
	detail:{
		url: `${config.API_URL}/cameraDetail/`,
		name: "获取摄像头详情",
		get: async function(id,data){
			return await http.get(this.url+id+"/", data);
		}
	},
	CameraDetail:{
		url: `${config.API_URL}/query/Camera/`,
		name: "单个摄像头的设备详情",
		get: async function(id,data){
			return await http.get(this.url+id+"/", data);
		}
	},
	setReagion:{
		url: `${config.API_URL}/GB28281/SetRegion/`,
		name: "单个摄像头的设备详情",
		post: async function(id,data){
			return await http.post(this.url+id+"/", data);
		}
	},
	
	getCameraCcid: {
		url: `${config.API_URL}/qxCamera/device/getCameraIdCcid`,
		name: "获取摄像头ccid",
		get: async function(data) {
			return await http.get( this.url, data)
		}
    },

}