<template>
    <div class="layoutBox">
        <el-divider >布局样式</el-divider>
        <div class="layout-box">
            <el-tooltip effect="dark" content="纵向" placement="top" :show-after="200">
                <div :class="['layout-item layout-vertical', { 'is-active': layout == 'verticalMenu' }]" @click="setLayout('verticalMenu')">
                <div class="layout-dark"></div>
                <div class="layout-container">
                    <div class="layout-light"></div>
                    <div class="layout-content"></div>
                </div>
                <el-icon v-if="layout == 'vertical'">
                    <CircleCheckFilled />
                </el-icon>
                </div>
            </el-tooltip>
            <el-tooltip effect="dark" content="经典" placement="top" :show-after="200" v-if="false">
                <div :class="['layout-item layout-classic', { 'is-active': layout == 'classic' }]" @click="setLayout('horizontalMenu')">
                <div class="layout-dark"></div>
                <div class="layout-container">
                    <div class="layout-light"></div>
                    <div class="layout-content"></div>
                </div>
                <el-icon v-if="layout == 'classic'">
                    <CircleCheckFilled />
                </el-icon>
                </div>
            </el-tooltip>
            <el-tooltip effect="dark" content="横向" placement="top" :show-after="200">
                <div :class="['layout-item layout-transverse', { 'is-active': layout == 'horizontalMenu' }]" @click="setLayout('horizontalMenu')">
                <div class="layout-dark"></div>
                <div class="layout-content"></div>
                <el-icon v-if="layout == 'transverse'">
                    <CircleCheckFilled />
                </el-icon>
                </div>
            </el-tooltip>
            <el-tooltip effect="dark" content="分栏" placement="top" :show-after="200" v-if="false">
                <div :class="['layout-item layout-columns', { 'is-active': layout == 'columns' }]" @click="setLayout('columns')">
                <div class="layout-dark"></div>
                <div class="layout-light"></div>
                <div class="layout-content"></div>
                <el-icon v-if="layout == 'columns'">
                    <CircleCheckFilled />
                </el-icon>
                </div>
            </el-tooltip>
        </div>
        <el-divider >全局样式</el-divider>
        <div class="layout-style">
            <div class="fl_just_sp-around" style="width: 100%;">
               <div>暗黑模式</div>
                <el-switch
                v-model="$store.state.theme.isDarkness"
                @change="changeDark"
                active-color="#13ce66"
                inactive-color="#C6C6C6">
                </el-switch>
            </div>
        </div>
    </div>
</template>

<script>
import {mapMutations} from 'vuex'
export default{
    data(){
        return{
            layout:''
        }
    },
    computed:{
       
    },
    methods:{
         ...mapMutations(['changeLayout']),
        setLayout(name){
            this.layout = name
            this.changeLayout(name)
        },
        changeDark(val){
            let html = document.documentElement
            if(val){
                html.classList.add('dark')
            }else{
                html.classList.remove('dark')
            }
        }
    }
}

</script>

<style lang='scss' scoped>
@import "@/views/home/layoutSet/index.scss";

</style>