<template>
    <div style="width: 100%;height: 100%;">
		<!-- <el-image style="width: 45px; height: 45px" src="@/assets/img/HomePage/camera.png"  fit="fit"></el-image>    -->

        <el-row :gutter="15">
            <el-col :span="3">
                <!-- <div style="margin-left:15px">
                    <div id="device" style="width: 45px; height: 45px;"  >
                        <el-image style="width: 45px; height: 45px" :src="url"  fit="fill"></el-image>   
                    </div>
                </div> -->
            </el-col>
        </el-row>

        <el-row :gutter="15" style="width:100%;height: 100%;">
            <el-col :span="24">
                <div style="border: 1px solid #444858;height: 100%;width: 100%;">
                    <div id="map" class="map" style="width: 100%;" :style={height:StyWidth}></div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import "ol/ol.css";
import ImageLayer from "ol/layer/Image";
import Map from "ol/Map";
import Projection from "ol/proj/Projection";
import Static from "ol/source/ImageStatic";
import View from "ol/View";
import Overlay from "ol/Overlay";
import { getCenter } from "ol/extent";
import $ from 'jquery'



export default {
	// components: {
	// 	ImageLayer,
	// 	Map,
	// 	Projection,
	// 	Static,
	// 	View,
	// 	getCenter,
	// 	Overlay
	// },
	props: [
		'AxiosURL',
		'position',
		'StyWidth'
	],
	data() {
		return {
			map:{},
			floor_img:"",
			w:0,
			h:0,
			imgInfo: {}, // 存图片的宽高信息
			url:'https://portal.zaqiot.com/static/images/smokedetector_warning.png',
			device_show:true,
			map_click:null,
			map_pointermove:null,
			marker:null,
			coord:null
		}
	},
	computed: {

	},
	watch: {},
	created() {

	},
	mounted() {
		// this.initMap()
	},
	methods: {
		async initMap() {
			this.$forceUpdate()
			let img = await this.util(this.AxiosURL)
			this.w = img.width
			this.h = img.height
			let extent = [0, 0, img.width,img.height];
			let projection = new Projection({
				code: "xkcd-image",
				units: "pixels",
				extent: extent
			});

			this.map = new Map({
				layers: [
					new ImageLayer({
						source: new Static({
							url:this.AxiosURL,
							projection: projection,
							imageExtent: extent
						})
					})
				],
				target: "map",
				view: new View({
					projection: projection,
					center: getCenter(extent),
					zoom: 1,
					maxZoom: 4,
					minZoom: 1
				})
			});

			this.initMarker()

		},
		util(data){
			return new Promise((resolve, reject) => {
				let img = new Image()
				img.src = data
				img.onload = function () {
					resolve({
						width: img.width,
						height: img.height
					})
					reject('error message')
				}
			})
		},
		savePoint(coord){
			this.coord = coord
			this.$emit('getCoord', coord)
		},
		addPoint(){
			let that = this
			// this.map_pointermove = this.map.on("pointermove",function(evt){
			// 	that.addMarker(evt)
			// },that)

			this.map_click = this.map.on("click",function(evt){
				that.addMarker(evt)
				// that.map.events.unregister("click")
				// that.map.events.unregister("pointermove")
				that.savePoint(evt.coordinate[0]+','+evt.coordinate[1])
			},that)
		},
		addMarker(evt){
			this.marker = new Overlay({
				position: evt.coordinate,//设置marker的位置，也可以通过overlay.setPosition方法来设置改变
				positioning: 'center-center',//选填参数，控制marker的相对位置
				element: document.getElementById('device'),
				stopEvent: false//选填参数，阻止默认事件行为
		})
			this.map.addOverlay(this.marker)
		},
		initMarker(){
			// if(this.coord!=null&&this.coord!=""){
				// var coordinate =  this.coord.split(",")
				
				var test = this.position.split(",")
				let testId= test[0].split(",")
				let   smokeMarker = $('<div style="position:relative" class="divimg" id="'+testId[1]+'"><img class="point" src="https://portal.zaqiot.com/static/images/smokedetector_warning.png" /></br><span style="color:rgb(0,164,255);position:absolute;left:-60%"></span></div>')[0]

				this.marker = new Overlay({
					position: [test[0],test[1]],//设置marker的位置，也可以通过overlay.setPosition方法来设置改变
					positioning: 'center-center',//选填参数，控制marker的相对位置
					element:smokeMarker ,
					stopEvent: false//选填参数，阻止默认事件行为
				})
				this.map.addOverlay(this.marker)
			// }

		},
	}
}

</script>
<style>
.map {
	width: 100%;
	height:100%;
	}
</style>
