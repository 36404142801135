import config from "@/config"
import http from "@/utils/request"
export default {
	add: {
		url: `${config.API_URL}/deviceSignalImport/`,
		name: "添加设备",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
	logoff:{
		url: `${config.API_URL}/deviceSignalLogoff/`,
		name: "注销设备",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    delete: {
		url: `${config.API_URL}/device/`,
		name: "删除设备",
		delete: async function(id,data){
			return await http.delete(this.url+id+"/", data);
		}
	},
    detail: {
		url: `${config.API_URL}/device/`,
		name: "查询设备详情",
		get: async function(id,data){
			return await http.get(this.url+id+"/", data);
		}
	},
    list: {
		url: `${config.API_URL}/device/`,
		name: "查询设备列表",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
    update: {
		url: `${config.API_URL}/device/`,
		name: "修改设备",
		patch: async function(id,data){
			return await http.patch(this.url+id+"/", data);
		}
	},
	InstructionRecord:{
		url: `${config.API_URL}/deviceCommandRecord/`,
		name: "查询设备下发指令记录",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
	select:{
		url: `${config.API_URL}/searchDeviceId/`,
		name: "下拉查询场所列表",
		get: async function(data){
			if(data!=null&&data!=""){
				return await http.get(this.url+data+"/");
			}else{
				return await http.get(this.url);
			}
		}
	},
	initDevice:{
		url: `${config.API_URL}/initDevice/`,
		name: "批量初始化设备",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
	moveDeviceEntity:{
		url: `${config.API_URL}/moveDeviceEntity/`,
		name: "转移单位",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
	removeDeviceEntity:{
		url: `${config.API_URL}/removeDeviceEntity/`,
		name: "解除绑定",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
	clearFault:{
		url: `${config.API_URL}/clearFault/`,
		name: "清除故障",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
	deviceArrange:{
		url: `${config.API_URL}/deviceArrange/`,
		name: "设备布防/撤防",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
	lockDevice:{
		url: `${config.API_URL}/lockDevice/`,
		name: "锁定/解锁设备",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
	bind:{
		url: `${config.API_URL}/newBindDevice/`,
		name: "绑定设备",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
	orderList:{
		url: `${config.API_URL}/deviceOrder/`,
		name: "指令",
		get: async function(id,data){
			return await http.get(this.url+id+"/", data);
		}
	},
	fireOrder:{
		url: `${config.API_URL}/deviceFireOrder/`,
		name: "指令下发",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
	cameraControl:{
		url: `${config.API_URL}/cameraPTZ/`,
		name: "摄像头控制",
		post: async function(id,data){
			return await http.post(this.url+id+'/', data);
		}
	},
	cameraPlayBack:{
		url: `${config.API_URL}/cameraRecord/`,
		name: "摄像头回放数据",
		get: async function(id,data){
			return await http.get(this.url+id+'/', data);
		}
	},
	carmeraBackPlay:{
		url: `${config.API_URL}/cameraRecord/`,
		name: "摄像头回放数据",
		post: async function(id,data){
			return await http.post(this.url+id+'/', data);
		}
	},
	blockDevice: {
		url: `${config.API_URL}/addPreventDeviceAlarm/`,
		name: "屏蔽设备",
		post: async function(id){
			return await http.post(this.url , id);
		}
	},
	getBlackAlarmDeviceList: {
		url: `${config.API_URL}/preventDeviceAlarm/`,
		name: "查询屏蔽报警列表",
		get: async function() {
			return await http.get(this.url);
		}
	},
	deletePreventDeviceAlarm: {
		url: `${config.API_URL}/deletePreventDeviceAlarm/`,
		name: "删除屏蔽报警",
		post: async function (id) {
			return await http.post(this.url, id);
		}
	},
    delRangeTime: {
        url: `${config.API_URL}/alarmTimeRange/del`,
        name: "批量取消设备报警时间段",
        post: async function(data) {
            return await http.post(this.url, data);
        }
    },
    setRangeTime: {
        url: `${config.API_URL}/alarmTimeRange/set`,
        name: "批量设置设备报警时间段",
        post: async function ( data ) {
            return await http.post( this.url , data );
        }
    },
    getAlarmTimeRange: {
        url: `${config.API_URL}/alarmTimeRange/`,
        name: "查询设备报警时间段",
        get: async function ( deviceId ) {
            return await http.get( this.url + deviceId );
        }
    },
    
}