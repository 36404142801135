<template>
    <div class="logoBox">
        <el-image
            v-if="entityLogo"
            style="width: 100px; height: 100px"
            :src="getUrl(entityLogo)"
            fit="fill">
        </el-image>
        <span  style="font-size: 16px; color: #3590f8;" >
            {{ entityName }}
        </span>
    </div>
</template>

<script>
export default{
    data(){
        return{
            entityLogo:'',
            entityName:'',
        }
    },
    mounted(){
        this.entityLogo = JSON.parse(localStorage.getItem("AllTitle")).logo
        this.entityName = JSON.parse(localStorage.getItem("AllTitle")).entity_name
    },
    methods:{
        getUrl(url){
            return this.$TOOL.getMediaUrl(url)
        },
        // test(){
        //     let html = document.documentElement
        //     html.classList.add('dark')
        // }
    }
}

</script>

<style lang='scss' scoped>
.logoBox{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>