import config from "@/config"
import http from "@/utils/request"

export default {
	add: {
		url: `${config.API_URL}/eventRules/`,
		name: "添加事件联动规则",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    delete: {
		url: `${config.API_URL}/eventRules/`,
		name: "删除预警规则",
		delete: async function(id,data){
			return await http.delete(this.url+id+"/", data);
		}
	},
    detail: {
		url: `${config.API_URL}/eventRules/`,
		name: "查询事件联动规则详情",
		get: async function(id,data){
			return await http.get(this.url+id+"/", data);
		}
	},
    list: {
		url: `${config.API_URL}/eventRules/`,
		name: "查询事件联动规则列表",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
    update: {
		url: `${config.API_URL}/eventRules/`,
		name: "修改事件联动规则",
		patch: async function(id,data){
			return await http.patch(this.url+id+"/", data);
		}
	},
	sysAcountList:{
		url: `${config.API_URL}/eventRules/`,
		name: "查询预警规则关联的系统用户",
		get: async function(id,data){
			return await http.get(this.url+id+"/account_manage/", data);
		}
	},
	sysAcountAdd:{
		url: `${config.API_URL}/eventRules/account_manage/`,
		name: "新增规则关联的系统用户",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
	sysAcountDelete:{
		url: `${config.API_URL}/eventRules/account_manage/`,
		name: "删除规则关联的系统用户",
		delete: async function(data){
			return await http.delete(this.url, data);
		}
	},
	tempAcountList:{
		url: `${config.API_URL}/eventRules/`,
		name: "查询预警规则关联的临时用户",
		get: async function(id,data){
			return await http.get(this.url+id+"/tempAccount/", data);
		}
	},
	tempAcountAdd:{
		url: `${config.API_URL}/eventRules/tempAccount/`,
		name: "新增规则关联的临时用户",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
	tempAcountDelete:{
		url: `${config.API_URL}/eventRules/tempAccount/`,
		name: "删除规则关联的临时用户",
		delete: async function(data){
			return await http.delete(this.url, data);
		}
	}
}
