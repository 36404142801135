<template>
    <div class="menuBreadBox">
        <div id="menuBox" class="mentTabBox fl_align_cen">
           
            <div class="tags" id="menuTags" ref="menuTags">
              <el-tag
                :key="tag.name" 
                type="danger"
                v-for="(tag,index) in tags"
                :closable="tag.name!=='home'"
                :disable-transitions="false"
                @close="handleClose(tag,index)"
                @click="changeMenu(tag)"
                :effect="$route.name === tag.name ? 'dark' : 'plain'">
                {{tag.label}}
              </el-tag>
            </div>
           
        </div>
    </div>
      
  </template>
  <script>
  
  import { mapState,mapMutations } from 'vuex'
  export default {
   name: 'CommonTags',
   computed:{
      ...mapState({
          tags:state=>state.tab.tabsList
      })
    },
    data(){
      return{
        menuTagsWhidth:null,
  
        childrenAllWhidth:null,
      }
    },
    mounted(){
      this.scrollInit();
    //   this.test()
    },
    watch:{
      tags:{
        handler(){
        
          // 每个tag的长度
        //   let num = this.$refs.menuTags.children.length
          // 每次显示最后一个
          this.$nextTick(()=>{
            // this.$refs.menuTags.scrollLeft += (this.$refs.menuTags.children[num].offsetWidth+2)
            // this.$refs.menuTags.scrollWidth = 0
             this.$refs.menuTags.scrollLeft += (this.$refs.menuTags.scrollWidth-700)
          })
        }
      }
    },
    methods:{
      ...mapMutations({
          close:'closeTab'
      }),
      changeMenu(tag){
          //在走一遍vuex中 selectMenu方法，将tag也是内容传递进去
          this.$store.commit('selectMenu',tag)
          this.$router.push({name:tag.name})
      },
      handleClose(tag,index){
        let length=this.tags.length-1;
        if( length == 0 ) return;
        this.close(tag)
        if (tag.name !== this.$route.name) {
          return
        }
        // 判断vuex中的tabsList下标，如果跟长度相等，跳转后最一个页面
        
        if(index===length){
         this.$router.push({name:this.tags[index-1].name})
        }else{
         this.$router.push({name:this.tags[index].name})
        }      
      },
      scrollInit(){
          // 获取要绑定事件的元素
        const nav = document.getElementById("menuTags")
        // document.addEventListener('DOMMouseScroll', handler, false)
        // 添加滚轮滚动监听事件，一般是用下面的方法，上面的是火狐的写法
        nav.addEventListener('mousewheel', handler, false)
        // 滚动事件的出来函数
        function handler(event) {
          // 获取滚动方向
          const detail = event.wheelDelta || event.detail;
          // 定义滚动方向，其实也可以在赋值的时候写
          const moveForwardStep = 1;
          const moveBackStep = -1;
          // 定义滚动距离
          let step = 0;
          // 判断滚动方向,这里的100可以改，代表滚动幅度，也就是说滚动幅度是自定义的
          if (detail < 0) {
            step = moveForwardStep * 100;
          } else {
            step = moveBackStep * 100;
          }
         
          // 对需要滚动的元素进行滚动操作
          nav.scrollLeft += step;
        }
      },
  
      test(){
        this.menuTagsWhidth = document.getElementById("menuTags")
      },
      leftChange(){
        this.$refs.menuTags.scrollLeft -=80
      },
      rightChange(){
        this.$refs.menuTags.scrollLeft +=80
  
      },
  
  
      // tags重新点击后获得数据
      tiao(val){
        for(let i =0;i<this.tags.length;i++){
            if(this.tags[i].name==val){
                this.jianShao(i)
            }
        }
      },
  
      // 如果相等就重新给滚动条位置
      jianShao(num){
        let list = this.tags.slice(0,num+1).length
        
        let width =0 
        for(let i = 0; i<list;i++){
          width += (this.$refs.menuTags.children[i].offsetWidth+2)
        }
        
        setTimeout(()=>{
          this.$refs.menuTags.scrollLeft += width
          if(width < 700){
            this.$refs.menuTags.scrollLeft = 0
          }else{
            this.$refs.menuTags.scrollLeft = width-700
          }
        },60)
      },
  
      clearTage(){
        this.$store.state.tab.tabsList =[]
      }
    }
  }
  
  </script>
  <style lang="scss" scoped>
  @import "@/assets/scss/theme/handle";
  @import "@/assets/scss/flex/flex-all.scss";
  .menuBreadBox{
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: center
  }
   .el-tag{
      margin:1px;
    } 
      .tags{
          display: flex;   
          flex-wrap: nowrap;
          align-items: flex-end;
          width: 100%;
          height: 30px;
          // @include background_color("tag_bg");
          overflow-y: overlay;
          position: relative;
      
      }
    .tags .el-tag{
      display:inline-block;
    //   @include font_color("tag_font_color");
    color: #77797c ;
      height: 25px;
      line-height: 25px;
      padding: 0 12px;
      margin: 0 5px;
      cursor: pointer;
      flex-shrink: 0;
      border-bottom: none;
      border-radius: 8px 8px 0 0;
      border: 0
    }
  
    .tags .el-tag--dark{
        //   @include background("tag_bg_select");
        //   @include font_color("tag_font_select");
        //   @include border("tag_border_select");
        background-color: rgb(235,245,255);
        // color: rgb(64,158,255) !important;
        // i{
        //     color: rgb(64,158,255) !important;
        // }
    }
    ::v-deep.tags .el-tag--dark.el-tag--danger .el-tag__close{
      color: rgb(64,158,255);
    }
    ::v-deep.tags .el-tag--dark.el-tag--danger .el-tag__close:hover{
      color: #fff;
    }
  //   ::v-deep .el-tag--plain.el-tag--info .el-tag__close:hover{
  //     @include font_color("tag_close_color");
  //   }
  
    ::-webkit-scrollbar {
      /* 隐藏滚动条 */
    //   display: none;
        width: 100%;
        height: 5px;
        background-color: #fff;
  }
  
::-webkit-scrollbar-thumb{
    border-radius: 8px;
    background-color: #a3a3a3;
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
}
  
  .leftbox{
    width: 30px;
    height: 30px;
    margin-right: 5px;
    background-color: rgb(245,247,250);
    border-radius: 50%;
    &:hover{
      background-color: rgb(161, 163, 165);
      cursor: pointer;
    }
  }
  .rightbox{
    width: 30px;
    height: 30px;
    margin-left: 5px;
    background-color: rgb(245,247,250);
    border-radius: 50%;
    &:hover{
      background-color: rgb(161, 163, 165);
      cursor: pointer;
    }
  }
  
  
  .clearBox{
    width:50px;height:60px;
    display:flex;
    justify-content:center;
    align-items:center;
  
    .clearBtn{
      width:60%;
      height:50%;
      border-radius:50%;
      border:1px solid rgb(236, 154, 154);
      display:flex;
      justify-content:center;
      align-items:center;
      cursor: pointer;
      &:hover{
        border-color:rgb(194, 61, 61) ;
        background-color: rgba(235, 113, 97, 0.2);
        i{
            font-size:20px;color:rgb(194, 61, 61)
          }
      }
      i{
        font-size:20px;color:rgb(236, 154, 154)
      }
    
    }
  
  }
  

  .mentTabBox{
    width: 98%;
    height: 35px;
    background-color: #fff;
    display: flex;
    align-items: flex-end;
    border: none;
    padding:0 5px;
    box-sizing: border-box;
    border-radius:0 0 12px 12px ;
    padding: 0 14px;
  }
  </style>