import config from "@/config"
import http from "@/utils/request"

export default {
    update: {
		url: `${config.API_URL}/accountSwitch/`,
		name: "修改用户开关信息",
		patch: async function(id,data){
			return await http.patch(this.url+id+"/", data);
		}
	},
}
