import config from "@/config"
import http from "@/utils/request"

export default {
	add: {
		url: `${config.API_URL}/role/`,
		name: "添加角色",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    delete: {
		url: `${config.API_URL}/role/`,
		name: "删除角色",
		delete: async function(id,data){
			return await http.delete(this.url+id+"/", data);
		}
	},
    list: {
		url: `${config.API_URL}/role/`,
		name: "查询角色列表",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
    update: {
		url: `${config.API_URL}/updateRole/`,
		name: "修改角色",
		post: async function(id,data){
			return await http.post(this.url+id+"/", data);
		}
	},
	detail:{
		url: `${config.API_URL}/role/`,
		name: "查询所有角色列表",
		get: async function(id,data){
			return await http.get(this.url+id+"/", data);
		}
	}
}
