import config from "@/config"
import http from "@/utils/request"

export default {
    list: {
		url: `${config.API_URL}/floorList/`,
		name: "楼层列表",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
	add: {
		url: `${config.API_URL}/floor/`,
		name: "添加楼层",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    delete: {
		url: `${config.API_URL}/floor/`,
		name: "删除楼层",
		delete: async function(id,data){
			return await http.delete(this.url+id+"/", data);
		}
	},
    update: {
		url: `${config.API_URL}/floor/`,
		name: "修改楼层",
		patch: async function(id,data){
			return await http.patch(this.url+id+"/", data);
		}
	},
    updateImage:{
        url: `${config.API_URL}/floorImage/`,
		name: "修改楼层图片",
		post: async function(id,data){
			return await http.post(this.url+id+'/',data);
		}
    },
	detail: {
		url: `${config.API_URL}/floor/`,
		name: "查询楼层详情",
		get: async function(id,data){
			return await http.get(this.url+id+"/", data);
		}
	},

}
