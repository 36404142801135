<template>
    <el-menu 
        :default-active="activePath"
        class="el-menu-vertical-demo"
        mode="horizontal"
        @select="gotoChile"
        :unique-opened="true"
        >
        <template v-for="cur in routes" >
            <el-submenu  :index="cur.path" v-if="cur.children && cur.children.length > 0" :key="cur.path">
                <template slot="title">
                    <i class="iconfont" :class="cur.Meta.icon"></i>
                    <span>{{ cur.Meta.title }}</span>
                </template>
                <childrenMenu :chridMenuList="cur.children"></childrenMenu>
            </el-submenu>
            <el-menu-item v-else :index="cur.path" :key="cur.path">
                <i class="iconfont" :class="cur.Meta.icon"></i>
                <span>{{ cur.Meta.title }}</span>
            </el-menu-item>
        </template>
        </el-menu>
</template>
<script>
import childrenMenu from "@/components/layout/menu/chridMenu.vue"

export default{
    data(){
        return{
            routes:[],
            bigTitle:'',
            entityLogo:'',
            entityName:"",
            newPath:'',
            langther:'',
            activePath:'home'
        }
    },
    components:{
        childrenMenu
    },
    mounted(){
        this.routes = JSON.parse(localStorage.getItem("menu"))     //使用JSON,拿到数据并使用
    },
    methods:{
          /* eslint-disable */
        async gotoChile(key, keyPath) {
            let that = this
            this.$emit('getMenuName',key.split(",")[1])
                let ListMenu = JSON.parse(localStorage.getItem("menu"))
                await this.getMeta(ListMenu,key)
                // this.$router.push(key)
                this.$router.replace({
                    path: that.newPath[0]
                });
                this.$store.commit("selectMenu", that.newPath);
        },

        // 拿到meta数据
        async getMeta(list,title){
            await list.forEach( item=>{
               if(item.path == title){
                    this.newPath =[
                        item.path,
                        item.name,
                        item.Meta.title,
                        item.Meta.icon
                    ]
                    return 
               }else{
                if(item.children){
                    // 有子
                    this.getMeta(item.children,title)
                }
               }
            })
        },
    },
}
</script>
<style scoped lang="scss">

.el-menu-vertical-demo{
    /* white-space:nowrap; */
    height:54px;
    box-sizing: border-box;
    width:100%;
    /* overflow:auto; */
    display:flex;
    overflow-y: hidden;
    overflow-x: auto;
    position: relative;
    border-bottom: 0;
}

::v-deep .el-menu--horizontal{
    border: 0;
}

/* 设置滚动条的宽度、高度、背景色和边框样式 */
.el-menu-vertical-demo::-webkit-scrollbar {
  width: 0;
  height: 0;
  background-color: rgba(245,245,245,.5);
  border-radius: 5px;
  position: absolute !important;
  bottom: 0px;
  -webkit-overflow-scrolling: touch;
}
.el-menu-vertical-demo:hover::-webkit-scrollbar {
  width: 0;
  height: 6px;
  background-color: rgba(245,245,245,.4);
  border-radius: 5px;
  position: absolute !important;
  bottom: 0px;
}



/* 设置滚动条滑块的背景色和圆角 */
.el-menu-vertical-demo::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
  position: absolute;
  bottom: 0px;
}
</style>