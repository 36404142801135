import config from "@/config"
import http from "@/utils/request"


export default {
    // --------------------------------------------资费套餐----------------------------------------------------
    queryPricingPackage: {
        url: `${config.API_URL}/payment/queryPricingPackage`,
        name: "查询资费套餐列表",
        get: async function (data) {
            return await http.get(this.url, data);
        } 
    },
    
    updatePricingPackage: {
        url: `${config.API_URL}/payment/updatePricingPackage/`,
        name: "修改资费套餐",
        patch: async function(packageId, data) {
            return await http.patch( this.url + packageId, data);
        }
    },

    delPricingPackage: {
        url: `${config.API_URL}/payment/deletePricingPackage/`,
        name: "删除资费套餐",
        delete: async function(packageId) {
            return await http.delete(this.url + packageId);
        }
    },
    addPricingPackage: {
        url: `${config.API_URL}/payment/addPricingPackage/`,
        name: "添加资费套餐",
        post: async function(data) {
            return await http.post(this.url, data)
        }
    },

    queryPricingPackageDevice: {
        url: `${config.API_URL}/payment/queryPricingPackageDevice`,
        name: "查询资费关联的设备列表",
        get: async function( data ){
            return await http.get( this.url, data );
        }
    },

    addPricingPackageDevice: {
        url: `${config.API_URL}/payment/addPricingPackageDevice`,
        name: "添加资费关联的设备",
        post: async function( data ) {
            return await http.post( this.url, data );
        }
    },
    deletePricingPackageDevice: {
        url: `${config.API_URL}/payment/deletePricingPackageDevice`,
        name: "删除资费关联的设备",
        delete: async function( data ) {
            return await http.delete( this.url, data );
        }
    },

    searchRelateUser: {
        url: `${config.API_URL}/payment/queryPricingPackageAccount`,
        name: "查询资费关联的用户",
        get: async function( data ) {
            return await http.get( this.url, data );
        }
    },
    deleteRelateUser: {
        url: `${config.API_URL}/payment/deletePricingPackageAccount`,
        name: "删除资费关联的用户",
        delete: async function( data ) {
            return await http.delete( this.url, data );
        }
    },
    addRelateUser: {
        url: `${config.API_URL}/payment/addPricingPackageAccount`,
        name: "添加资费关联的用户",
        post: async function( data ) {
            return await http.post( this.url, data );
        }
    },

    searchRelateUnit: {
        url: `${config.API_URL}/payment/queryPricingPackageEntity`,
        name: "查询资费关联的单位",
        get: async function( data ) {
            return await http.get( this.url, data );
        }
    },
    addRelateUnit: {
        url: `${config.API_URL}/payment/addPricingPackageEntity`,
        name: "添加资费关联的单位",
        post: async function( data ) {
            return await http.post( this.url, data );
        }
    },
    deleteRelateUnit: {
        url: `${config.API_URL}/payment/deletePricingPackageEntity`,
        name: "删除资费关联的单位",
        delete: async function( data ) {
            return await http.delete( this.url, data );
        }
    },

    searchRelateModel: {
        url: `${config.API_URL}/payment/queryPricingPackageModel`,
        name: "查询资费关联的模型",
        get: async function( data ) {
            return await http.get( this.url, data );
        }
    },
    addRelateModel: {
        url: `${config.API_URL}/payment/addPricingPackageModel`,
        name: "添加资费关联的模型",
        post: async function( data ) {
            return await http.post( this.url, data );
        }
    },
    deleteRelateModel: {
        url: `${config.API_URL}/payment/deletePricingPackageModel`,
        name: "删除资费关联的模型",
        delete: async function( data ) {
            return await http.delete( this.url, data );
        }
    },





    // --------------------------------------------支付快照----------------------------------------------------
    queryPaySnapshoot: {
        url: `${config.API_URL}/payment/queryPaymentSnapshots`,
        name: "查询支付快照",
        get: async function(data) {
            return await http.get( this.url, data );
        }
    },

    queryPaySnapshootDetail: {
        url: `${config.API_URL}/payment/queryPaymentSnapshotsDetail`,
        name: "查询快照详情",
        get: async function(data) {
            return await http.get( this.url, data );
        }
    },

    // --------------------------------------------订单记录----------------------------------------------------
    queryOrderRecord: {
        url: `${config.API_URL}/payment/queryOrderRecord`,
        name: "查询订单记录",
        get: async function(data) {
            return await http.get( this.url, data );
        }
    },
    queryOrderRecordDevice: {
        url: `${config.API_URL}/payment/queryOrderRecordDevice`,
        name: "查询订单关联的设备",
        get: async function(data) {
            return await http.get( this.url, data );
        }
    },
    queryOrderRecordDetail: {
        url: `${config.API_URL}/payment/queryOrderRecordDetail`,
        name: "查询订单详情",
        get: async function(data) {
            return await http.get( this.url, data );
        }
    },

    // --------------------------------------------微信支付订单记录----------------------------------------------------
    queryWXOrderRecord: {
        url: `${config.API_URL}/payment/queryWXOrderRecord`,
        name: "微信支付订单记录",
        get: async function(data) {
            return await http.get( this.url, data );
        }
    },

    // --------------------------------------------缴费、充值----------------------------------------------------
    summitOrderAPI: {
        url: `${config.API_URL}/payment/createUserOrder/`,
        name: "提交订单",
        post: async function( data ) {
            return await http.post( this.url, data );
        }
    }, 
    

    // --------------------------------------------购物车----------------------------------------------------
    addPayDeviceList: {
        url: `${config.API_URL}/payment/addPayDeviceList/`,
        name: "添加设备缴费清单（添加购物车）",
        post: async function (data) { 
            return await http.post(this.url, data);
        }
    },
    deletePayDeviceList: {
        url: `${config.API_URL}/payment/deletePayDeviceList/`,
        name: "删除设备缴费清单（删除购物车）",
        post: async function (data) {
            return await http.post(this.url, data);
        }
    },
    queryPayDeviceList: {
        url: `${config.API_URL}/payment/queryPayDeviceList/`,
        name: "查询设备缴费清单",
        get: async function () {
            return await http.get(this.url);
        }
    },

    autoCreateOrder: {
        url: `${config.API_URL}/payment/autoCreateWeChatOrder/`,
        name: "自动生成订单接口",
        post: async function (data) {
            return await http.post(this.url, data);
        }
    }
}