import config from "@/config"
import http from "@/utils/request"

export default {
	list: {
		url: `${config.API_URL}/faultList/`,
		name: "获取故障记录",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
	deviceFault:{
		url: `${config.API_URL}/deviceFault/`,
		name: "获取设备的故障记录",
		get: async function(id,data){
			return await http.get(this.url+id+'/', data);
		}
	},
    detail: {
		url: `${config.API_URL}/deviceFaultDetail/`,
		name: "查看故障记录详情",
		get: async function(id,data){
			return await http.get(this.url+id+'/', data);
		}
	},
    checkFault:{
		url: `${config.API_URL}/deviceFault/`,
		name: "处理故障记录",
		post: async function(id,data){
			return await http.post(this.url+id+'/', data);
		}
	},
    delete:{
        url: `${config.API_URL}/deviceFault/`,
		name: "删除故障记录",
		delete: async function(id,data){
			return await http.delete(this.url+id+'/', data);
		}
    },
    faultImage:{
        url: `${config.API_URL}/deviceFaultImage/`,
		name: "查看故障图片",
		get: async function(id,data){
			return await http.get(this.url+id+"/", data);
		}
    },
    addFaultImage: {
		url: `${config.API_URL}/deviceFaultImage/`,
		name: "添加故障图片",
		post: async function(id,data){
			return await http.post(this.url+id+"/", data);
		}
	},
    deleteFaultImage: {
		url: `${config.API_URL}/deviceFaultImage/`,
		name: "删除故障图片",
		delete: async function(data){
			return await http.delete(this.url, data);
		}
	},
}