<template>
    <!-- 后台首页 -->
    <div class="newHoemBigBox">
        <div class="newHoemBigBox_two" v-if="deviceTypeList.length > 0">
            <div class="crotonller_all_or_part_btn">
                <el-button type="primary" :disabled="isDisable_btn1"  @click="getDeviceTypeList(1)">{{ $t("basic.menubar.equipped") }}</el-button>
                <el-button type="primary" :disabled="isDisable_btn2"  @click="getDeviceTypeList(0)">{{ $t("basic.menubar.AllEquipment") }}</el-button>
            </div>
            <!-- 后台首页每一个设备的盒子 -->
            <div class="deviceWaibox" v-for="(item,index) in deviceTypeList" :key="index" @click="gotoDeviceList(item)">
                <div class="top">
                    <div class="imgbox  fl_all_cent">
                        <div :class="'typeItemIcon '+ getDeviceTypeCsss(item.bgIcon)"> </div>
                    </div>
                    <div class="textBox">
                        <!-- 设备名称 -->
                        <div class="title">
                            {{ $t(item.type_name) }}
                        </div>
                        <!-- 设备总数 -->
                        <div class="fl_just_sp-betw text">
                            <div class="flex">
                                <div style="padding-right: 10px;">
                                    {{$t("basic.deviceList.device_allNnum")}}:
                                </div>
                                <!-- 设备数量 --> 
                                <div class="num" :style="{color: ( item.count > 0 ? '#67c23a': '#909399') }">{{ item.count > 0 ? item.count : 0 }}  </div>
                            </div>
                        </div>
                    </div>

                    <!-- 是否存在设备tag -->
                    <el-tag type="success" effect="dark" style="position:absolute;top:5px;right:10px" size="mini" v-if="item.count>0">{{$t("basic.deviceList.OwnDevice")}}</el-tag>
                    <el-tag type="info" effect="dark" style="position:absolute;top:5px;right:10px" size="mini" v-else>{{$t("basic.deviceList.NoDevice")}}</el-tag>
                </div>
            </div>
        </div>
        <el-empty style="height: 100%; color: #333; width: 100%; flex: 1" v-else :image-size="160"></el-empty>
    </div>
</template>
<script> 
export default{
    data(){
        return{
            deviceList:[],
            deviceTypeList:[],
            isDisable_btn1: true, // 是否禁用点击
            isDisable_btn2: false, // 是否禁用点击
        }
    },
    mounted(){
        this.getDeviceTypeList(1)
    },
    methods:{
        gotoDeviceList(val){
            console.log(val);
            this.$router.push({
                name:'deviceList',
                params:{
                    deviceType:val.id,
                    deviceName:val.type_name
                }
            })
        },

        async getDeviceTypeList(type) {
            this.deviceTypeList = [];
            let res = await this.$API.device_type.list.get({
                page:1,
                limit:100,
                language: localStorage.getItem('theLanguage')
            });

            let language = localStorage.getItem('theLanguage');
            // 获取数据
            let res2 = await this.$API.DataLargeScreen.deviceTypeCount.get({language});

            let r1 = res.data.data;
            let r2 = res2.data
            // let newArr = null;
            // 有设备控制器, 全部设备控制器
            if ( type == 1) { // 有设备
                this.deviceTypeList = r2
                this.isDisable_btn1 = true;
                this.isDisable_btn2 = false;
                let str = "";

                if ( language == "zh" ) {
                    str = "id";
                } else {
                    str = "type_id"

                }
                for (let i = 0; i < r2.length; i++) { 
                    for (let j = 0; j < r1.length; j++) {
                        if (r2[i].id === r1[j][str]) {
                            this.deviceTypeList[i].bgIcon = r1[j].type_code
                            break;
                        }
                    }
                }
                this.isloading1 = false;
            }else if (type == 0) {
                // 全部设备
                this.isDisable_btn1 = false;
                this.isDisable_btn2 = true;
                this.deviceTypeList = r1;
                let str = "";
                if ( language == "zh" ) {
                    str = "id"
                } else {
                    str = "type_id"
                }
                for (let i = 0; i < r1.length; i++) { 
                    for (let j = 0; j < r2.length; j++) {
                        if (r1[i][str] === r2[j].id) {
                            this.deviceTypeList[i].count = r2[j].count
                        }
                    }
                }
                this.deviceTypeList.forEach(item => {
                    item.bgIcon = item.type_code
                })
                console.log("this.deviceTypeList", this.deviceTypeList)
                // newArr.forEach( item => {
                //     this.deviceTypeList.push({
                //         id: item.id,
                //         type_name: item.type_name,
                //         count: 0,
                //         bgIcon: item.type_code
                //     })
                // });

            }
            
        },
        
        
        get_haveDevice(){
            // console.log(123)
        },
        
        showDevice( type ){
            if( type == 0 ){
                // 全部设备
                this.getDeviceTypeList
            } else if(type == 1) {    
                // 无设备
 
            }else if( type == 2 ){
                // 有设备
            } 
        },
        
        // 控制后台首页设备图标
        getDeviceTypeCsss(code) {
            switch(code){
                case "GasDetector":
                case "4GGasDetector":
                case "GasIbm":
                    return "type_gas"
                    
                case "DoorContact":
                    return "type_door"
                    
                case "SmokeCO":
                    return "type_smokeCo"
                    
                case "433Ibm":
                case "AlarmGateway":
                    return "type_ibm"
                    
                case "ManualAlarm":
                    return "type_manual"
                    
                case "4G_industry_gas":
                case "0X81":
                    return "type_4gGas"
                    
                case "wan_camera":
                case "wan_Camera":
                case "ca_433Camera":
                    return "type_camera"
                    
                case "SmokeDetector":
                case "433SmokeDetector":
                case "4GSmokeDetector":
                    return "type_smoke"
                    
                case "ZaqSpeaker":
                    return "type_zaqSpeaker"
                    
                case "PressureDetector":
                    return "type_waterPressure"
                    
                case "NBGateway":
                    return "type_gateway"
                    
                case "CO":
                case "GasCo":
                    return "type_co"
                    
                case "SoundLightAlarm":
                    return "type_soundLight"
                    
                case "InfraedSmoker":
                    return "type_hwSmoke"
                    
                case "InputAndOutput":
                    return "type_inOutPut"
                    
                case "inputModal":
                    return "type_inputModel"
                    
                case "AICameraElectricCar":
                    return "type_aiCamera"
                    
                case "temperature":
                    return "type_temperature"
                    
                case "oneBtnHelp":
                    return "type_oneBtnHelp"
                    
                case "FD101-4G":
                    return "type_fd1014g"
                    
                case "HumitureAlarm":
                    return "type_humiture"
                    
                default:
                    return "type_default"
            }
        },

    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/theme/_handle.scss";
@import "@/assets/scss/flex/flex-all.scss";
@import "@/assets/scss/theme/handle";
.newHoemBigBox{
    width: 100%;
    height: 100%;
    // @include background-color('bg_gray');
    white-space: nowrap;
    .newHoemBigBox_two{
        width: 100%;
        height: 88vh;
        overflow: auto;
        align-content:flex-start;
        // justify-content: center;
        display: flex;
        flex-wrap: wrap;

        .crotonller_all_or_part_btn{
            height: 50px;
            width: 100%;
        }

       .deviceWaibox{
            // width: 32.5%;
            // height: 210px;
            position: relative;
            width: 24%;
            height: 130px;
            margin-bottom: 15px;
            margin-left: 6px;
            margin-right: 6px;
            box-sizing: border-box;
            background-color: #ffffff;
            border-radius: 12px;
            padding: 10px 15px;
            padding-left: 0;
            box-shadow:0 2px 8px 0 rgba(0,0,0,.3);
            transition: all 1s ;
            
            &:hover{
                background: #dee1e6;
                cursor: pointer;
                transition: all .2s ;
            }
            .top{
                width: 100%;
                height: 100%;
                display: flex;
                .imgbox{
                    width: 35%;
                    height: 100%;
                    i{
                        font-size: 80px;
                    }
                }
               .textBox{
                width: 65%;
                padding-top: 20px;
                font-size: 16px;
                .title{
                    width: 100%;
                    font-size: 18px;
                    font-weight: 600;
                    padding-bottom: 15px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-left: 0;
                    color: #121212;
                }
                .text{
                    color: #535252;
                    box-sizing: border-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    .num{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        // color: #67c23a;
                    }

                    // color: #135de1;
                }
               }
            }
            .bottom{
                width: 100%;
                height: 50px;
                box-sizing: border-box;
                padding-left: 10px;
                // background-color: antiquewhite;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .numbox{
                    width: 23%;
                    height: 30px;
                    border-radius: 15px;
                    background-color: rgb(241,244,255);
                    line-height: 30px;
                    box-sizing: border-box;
                    padding-left: 15px;
                    color: #a1a1a1;
                    font-size: 12px;
                    i{
                        font-size: 18px;
                    }
                    .num{
                        font-weight: 600;
                        font-size: 15px;
                        color: #141414;
                        width: 50%;
                        // background-color: aqua;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        
                    }
                }
            }
       }
    }
}

.typeItemIcon{
	width: 75px;
	height: 75px;
	background-repeat:no-repeat;
	background-size:cover;
	display: inline-block;
}

// 4G工业燃气
.type_4gGas{
	background-image: url('../../../assets/img/deviceType/4G-GAS.png');
}
// AI摄像头
.type_aiCamera{
	background-image: url('../../../assets/img/deviceType/AICamera.png');
}
//普通摄像头通用
.type_camera{
	background-image: url('../../../assets/img/deviceType/camera.png');
}
//一氧化碳
.type_co{
	background-image: url('../../../assets/img/deviceType/co.png');
}
//门磁
.type_door{
	background-image: url('../../../assets/img/deviceType/DoorContact.png');
}
//毫米雷达波
.type_fd1014g{
	background-image: url('../../../assets/img/deviceType/FD101-4G.png');
}
//燃气报警器通用图标
.type_gas{
	background-image: url('../../../assets/img/deviceType/GasDetector.png');
}
// 温湿度设备
.type_humiture{
	background-image: url('../../../assets/img/deviceType/HumitureAlarm.png');
}
// 主机通用图标
.type_ibm{
	background-image: url('../../../assets/img/deviceType/Ibm.png');
}
//红外烟感
.type_hwSmoke{
	background-image: url('../../../assets/img/deviceType/InfraedSmoker.png');
}
//输入输出模块
.type_inOutPut{
	background-image: url('../../../assets/img/deviceType/InputAndOutput.png');
}
// 中继模块
.type_inputModel{
	background-image: url('../../../assets/img/deviceType/inputModal.png');
}
// 手报
.type_manual{
	background-image: url('../../../assets/img/deviceType/ManualAlarm.png');
}
// 网关
.type_gateway{
	background-image: url('../../../assets/img/deviceType/NBGateway.png');
}
// 一键呼救主机
.type_oneBtnHelp{
	background-image: url('../../../assets/img/deviceType/oneBtnHelp.png');
}
// 水浸
.type_waterPressure{
	background-image: url('../../../assets/img/deviceType/PressureDetector.png');
}
// 烟感通用
.type_smoke{
	background-image: url('../../../assets/img/deviceType/smoke.png');
}
// 烟感CO复合
.type_smokeCo{
	background-image: url('../../../assets/img/deviceType/smokeco.png');
}
// 声光报警器
.type_soundLight{
	background-image: url('../../../assets/img/deviceType/SoundLightAlarm.png');
}
// 温度报警器
.type_temperature{
	background-image: url('../../../assets/img/deviceType/temperature.png');
}
// TT音箱
.type_zaqSpeaker{
	background-image: url('../../../assets/img/deviceType/ZaqSpeaker.png');
}
// 通用设备图标
.type_default{
	background-image: url('../../../assets/img/deviceType/default.png');
}

.deviceList-Box{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;
    box-sizing: border-box;
}
</style>