import config from "@/config"
import http from "@/utils/request"

export default {
	bind: {
		url: `${config.API_URL}/Camera433DeviceBind/`,
		name: "设备绑定",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
	unbind: {
		url: `${config.API_URL}/Camera433DeviceUnbind/`,
		name: "设备绑定",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
	defense:{
		url: `${config.API_URL}/Camera433DeviceArm/`,
		name: "设备布防",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    undefense: {
		url: `${config.API_URL}/Camera433DeviceDisarm/`,
		name: "设备撤防",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    setDefense: {
		url: `${config.API_URL}/Camera433DeviceArmNum/`,
		name: "设置防区",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    updateMqtt:{
        url: `${config.API_URL}/Camera433DeviceSetMqtt/`,
		name: "更新mqtt",
		post: async function(data){
			return await http.post(this.url, data);
		}
    },
    updateHttp:{
        url: `${config.API_URL}/Camera433DeviceSetHttp/`,
		name: "更新http",
		post: async function(data){
			return await http.post(this.url, data);
		}
    },
    list:{
        url: `${config.API_URL}/getCamera433Sd/`,
		name: "433烟感列表",
		get: async function(id,data){
			return await http.get(this.url+id+'/', data);
		}
    },
	soundLight:{
        url: `${config.API_URL}/433cameraIssuedLinkageSoundAndLightSelfTest/`,
		name: "联动声光报警器自检",
		post: async function(data){
			return await http.post(this.url, data);
		}
    },
	sprinklerValve:{
		url: `${config.API_URL}/433cameraIssuedSprinklerValve/`,
		name: "开关喷淋阀门",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
	unbindAll:{
		url: `${config.API_URL}/Camera433DeviceUnbindAll/`,
		name: "解绑所有设备",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
	algorithmControlledRelays:{
		url: `${config.API_URL}/issue/algorithmControlledRelays/`,
		name: "开关算法控制继电器",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
	remoteRelays:{
		url: `${config.API_URL}/issue/RemoteRelays/`,
		name: "开关远程继电器",
		post: async function(data){
			return await http.post(this.url, data);
		}
    },
    changeAddr: {
        url: `${config.API_URL}/update/Camera/installData/`,
        name: "修改摄像头子设备安装地址信息",
        post: async function (id, data) {
            return await http.post(this.url + id, data);
        }
    }
}