import config from "@/config"
import http from "@/utils/request"
// 查询情况
export default{
    add:{
		url: `${config.API_URL}/inspectionDanger/`,
		name: "新增检查情况",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    list:{
		url: `${config.API_URL}/inspectionDanger/`,
		name: "查询检查情况",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
	update:{
		url: `${config.API_URL}/inspectionDanger/`,
		name: "修改检查情况",
		patch: async function(id,data){
			return await http.patch(this.url+id+'/', data);
		}
	},
	

}