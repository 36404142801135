import config from "@/config"
import http from "@/utils/request"

export default {
    // deviceCount: {
	// 	url: `${config.API_URL}/deviceCount/`,
	// 	name: "查询设备相关数量数据",
	// 	get: async function(data){
	// 		return await http.get(this.url, data);
	// 	}
	// },
	deviceStateCount:{
		url: `${config.API_URL}/deviceStateCount`,
		name: "接口统计数据",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
    map:{
		url: `${config.API_URL}/siteRegion`,
		name: "地图中的经纬度",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
    deviceCount: {
		url: `${config.API_URL}/entityInstallCount/`,
		name: "单位设备安装数量",
		get: async function(id,data){
			return await http.get(this.url+id+'/', data);
		}
	},
    deviceTypeCount: {
		url: `${config.API_URL}/deviceTypeCount/`,
		name: "设备类型数量",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
	todayAlarmCount: {
		url: `${config.API_URL}/todayAlarmCount/`,
		name: "今日报警数量",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
    todayFaultCount: {
		url: `${config.API_URL}/todayFaultCount/`,
		name: "今日故障数量",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
    todayInstallCount: {
		url: `${config.API_URL}/todayInstallCount/`,
		name: "今日安装数量",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
    todayClosureFaultCount: {
		url: `${config.API_URL}/todayClosureFaultCount/`,
		name: "今日维保数量",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
    deviceAlarmTrend: {
		url: `${config.API_URL}/deviceAlarmTrend/`,
		name: "11天内报警趋势",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
    deviceFaultTrend: {
		url: `${config.API_URL}/deviceFaultTrend/`,
		name: "11天内故障趋势",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
	alarmLogData: {
		url: `${config.API_URL}/alarmLogData`,
		name: "用户报警记录",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
	deviceData: {
		url: `${config.API_URL}/deviceStatistics`,
		name: "设备统计",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
}
