import config from "@/config"
import http from "@/utils/request"
// 巡检任务
export default{
    add:{
		url: `${config.API_URL}/inspectionTask/`,
		name: "新增巡检任务",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    list:{
		url: `${config.API_URL}/inspectionTask/`,
		name: "查询任务列表",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
    detail:{
		url: `${config.API_URL}/inspectionTask/`,
		name: "查询任务详情",
		get: async function(id,data){
			return await http.get(this.url+id+"/", data);
		}
	},
	update:{
		url: `${config.API_URL}/inspectionTask/`,
		name: "修改任务",
		patch: async function(id,data){
			return await http.patch(this.url+id+'/', data);
		}
	},
	delete:{
		url: `${config.API_URL}/inspectionTask/`,
		name: "删除任务",
		delete: async function(id,data){
			return await http.delete(this.url+id+"/",data);
		}
	},
	

}