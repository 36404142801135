import config from "@/config"
import http from "@/utils/request"

export default {
	add: {
		url: `${config.API_URL}/deviceImport/`,
		name: "添加设备导入记录",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    delete: {
		url: `${config.API_URL}/deviceImport/`,
		name: "删除设备导入记录",
		delete: async function(id,data){
			return await http.delete(this.url+id+"/", data);
		}
	},
    list: {
		url: `${config.API_URL}/deviceImport/`,
		name: "查询设备导入记录列表",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
}