<template>
    <div>
        <template v-for="(item, index) in chridMenuList"  >
            <el-submenu  :index="item.path" v-if="item.children && item.children.length > 0" :key="item.path">
                <template slot="title">
                    <i class="iconfont" :class="item.Meta.icon" :style="{color: menuTC}"></i>
                    <span>{{ $t( item.Meta.title ) }}</span>
                </template>
                <!-- 子菜单 -->
                <childrenMenu :chridMenuList="item.children"></childrenMenu>
            </el-submenu>
            <el-menu-item v-else :index="item.path" :key="index" >
                <i class="iconfont" :class="item.Meta.icon" :style="{color: menuTC}"></i>
                <span>{{ $t( item.Meta.title ) }}</span>
            </el-menu-item>
        </template>
    </div>
</template>
 
<script>
import childrenMenu from '@/components/layout/menu/chridMenu.vue'
export default{
    data(){
        return{
            
        }
    },
    props:{
        chridMenuList:{
            type:Array,
            default:()=>{
                []
            }
        },
        localSet:{
            type:Object,
            default:()=>{
                return {}
            }
        },
    },
    components:{
        childrenMenu
    },
    methods:{

    },
    computed: {
        
        menuTC() {
            return this.localSet.menuTC?this.localSet.menuTC:"#a0a5bd";
        },
        HMC() {
            return this.localSet.HMC?this.localSet.HMC : "linear-gradient(to right, #0e56de, #2172eb, #3590f8)";
        },
        ATC() {
            return this.localSet.menuATC?this.localSet.ATC : "#409EFF";
        },
        HTC() {
            return this.localSet.HTC?this.localSet.HTC:"#fff";
        },
        MABC() {
            return this.localSet.MABC?this.localSet.MABC:"rgba(15, 87, 222, .4)";
        },
    },
}

</script>
<style lang="scss" scoped>
@import '@/assets/scss/menu/menu.scss';
@import '@/assets/scss/flex/flex-all';

// 未激活、鼠标未移入时icon图标的颜色
.iconfont{
    color: v-bind(menuTC);
}
// 鼠标放上去后 背景颜色修改和文字颜色修改
// 鼠标移入 时样式
::v-deep .el-submenu__title:hover,
.el-menu-item:hover {
    color: v-bind(HTC) !important;
    background: v-bind(HMC) !important;
    
    i {
        color: v-bind(HTC) !important
    }
}


// 选中后 menu的背景颜色 文字颜色 icon颜色 修改  激活后样式
.el-menu-item.is-active {
    // color: v-bind(ATC) !important;
    background: v-bind(MABC) !important;
    i {
        color: v-bind(ATC) !important;
    }
}

::v-deep .el-submenu__title:hover i,
.el-menu-item:hover i {
    // background: v-bind(HMC) !important;
    color: v-bind(HMC);
}

</style>