export const theme= {
    state:{
        tableHeight:'',
        noExTableHeight:'',
        detailWidth:'60%',

        layoutPattern:'verticalMenu',
        layoutDrawer:false,

        // 是否暗黑模型
        isDarkness:false,
        // 语言
        language:'zh',

        // 进入西乡前是否是英文模式
        xiXiangEn:false,
    },
    mutations:{
        changeTableHeight(state,val){
            state.tableHeight =val
        },
        changeNoExTableHeight(state,val){
            state.noExTableHeight =val
        },
        changeDetailWidth(state,val){
            state.detailWidth = val
        },

        changeLayout(state,val){
            state.layoutPattern = val
        },

        openDrwer(state){
            state.layoutDrawer = true 
        },

        closeDrwer(state){
            state.layoutDrawer = false
        },

        changeDarke(state){
            state.isDarkness = !state.isDarkness
        },
        changeLanguage(state,val){
            state.language = val
            localStorage.setItem('theLanguage',val)
        },
        changeXixiangEn(state){
            state.xiXiangEn = !state.xiXiangEn
        }
    },
    actions:{

    },
    getter:{

    }
}