// 摄像头型号
import config from "@/config"
import http from "@/utils/request"

export default {
    searchCameraModelList: {
        url: `${config.API_URL}/qxCamera/model/list`,
        name: "查询型号列表",
        get: async function (){
            return await http.get(this.url);
        }
    },
    addCameraModel: {
        url: `${config.API_URL}/qxCamera/model/add`,
        name: "添加摄像头型号",
        post: async function (data) {
            /** data: 
             * Name      string    chipId(必需)，
             */
            return await http.post(this.url, data);
        },
    },
    deleteCameraModel: {
        url: `${config.API_URL}/qxCamera/model/delete`,
        name: "删除摄像头型号",
        post: async function (data) {
            /** data: 
             * Id  integer   型号id(可需)，
             */
            return await http.post(this.url, data);
        },
    },
}
