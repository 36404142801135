import config from "@/config"
import http from "@/utils/request"

export default {
	add: {
		url: `${config.API_URL}/siteCollection/`,
		name: "添加场所收藏",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    delete: {
		url: `${config.API_URL}/siteCollection/`,
		name: "删除场所收藏",
		delete: async function(data){
			return await http.delete(this.url, data);
		}
	},
    list: {
		url: `${config.API_URL}/siteCollection/`,
		name: "查询场所收藏列表",
		get: async function(data){
			return await http.get(this.url, data);
		}
	}
}
