import config from "@/config"
import http from "@/utils/request"

export default{
   
    add:{
		url: `${config.API_URL}/addWork`,
		name: "新增值班",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    list:{
		url: `${config.API_URL}/workList`,
		name: "值班日志",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},

}