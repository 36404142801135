import config from "@/config"
import http from "@/utils/request"
// 异常类型
export default{
    add:{
		url: `${config.API_URL}/dangerType/`,
		name: "新增异常类型",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    list:{
		url: `${config.API_URL}/dangerType/`,
		name: "查询异常类型",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
	update:{
		url: `${config.API_URL}/dangerType/`,
		name: "修改异常",
		patch: async function(id,data){
			return await http.patch(this.url+id+'/', data);
		}
	},
	delete:{
		url: `${config.API_URL}/dangerType/`,
		name: "删除任务",
		delete: async function(id,data){
			return await http.delete(this.url+id+"/",data);
		}
	},
	

}