<template>
    <div class="ForgotPasswordbigbox">
        <div class="centent">
            <div class="title">
                吉凯达安防平台
            </div>
            <div class="form">
                <!-- form表单 -->
                <el-form  :model="SigninForm" ref="SigninForm" :rules="rules" >
                    <el-form-item prop="UserName">
                        <el-input placeholder="请输入账号" v-model="SigninForm.UserName" >
                           
                        </el-input>

                    </el-form-item>
                    <el-form-item  prop="phone">
                        <el-input placeholder="请输入手机号"  v-model="SigninForm.phone" style="background-color: rgb(72,84,109);border-radius: 5px;">
                        </el-input> 
                    </el-form-item>
                    <el-form-item prop="GraphicCode" >
                        <div style="display: flex;">
                            <el-input placeholder="请输入图形码" v-model="SigninForm.GraphicCode" style="background-color: rgb(72,84,109);width:65%;border-radius: 5px;">
                        </el-input> 
                            <div class="GraphicCode" @click="refresh" >
                                <GraphicCode ref="GraphicCode"  @ObtainCode="ObtainCode" ></GraphicCode>
                            </div>
                        </div>
                        
                    </el-form-item>
                    <el-form-item  prop="VerificationCode">
                        <div style="display: flex;">
                            <el-input placeholder="请输入验证码"  v-model="SigninForm.VerificationCode" style="background-color: rgb(72,84,109);width:65%;border-radius: 5px;">
                            </el-input> 
                            <div class="VerificationCode" @click="CheckVerificationCode">
                                获取验证码
                            </div>
                        </div>
                        
                    </el-form-item>
                    <el-form-item prop="NewPassword">
                        <el-input placeholder="请输入新密码" show-password v-model="SigninForm.NewPassword" style="background-color: rgb(72,84,109);border-radius: 5px;">
                        </el-input> 
                    </el-form-item>
                    <el-form-item style="display: flex;justify-content: center;margin-top: 60px;">
                        <el-button type="primary" @click="submitForm('SigninForm')" style="width:380px">{{$t("basic.public.confirmNospace")}}</el-button>
                    </el-form-item>   
                    <!-- <el-form-item>
                        <el-button type="primary" @click="onSubmit">{{$t("basic.patrolTask.add_submitFormBtn")}}</el-button>
                        <el-button>{{$t("basic.public.cancelNospace")}}</el-button>
                    </el-form-item> -->
                </el-form>
                <div class="login_bottom">
                   <div class='login_bottom_left'>
                        <span style="padding-right: 15px;">其他方式登录</span>
                        <i class="iconfont icon-weixin-copy  "  ></i>

                   </div>
                   <div class="login_bottom_right">
                        
                   </div>
                </div>
            </div>
           
        </div>
        <div class="QRcode">
            <div class="triangle" @click="goLogin">
               <span> 返回登录</span><i class="el-icon-d-arrow-right"></i>
            </div>
           
        </div>
        
    </div>
</template>
<script>
import GraphicCode from '@/components/GraphicCode.vue';
export default{
    name:'RegiterView',
    components:{
        GraphicCode
    },
    data(){
        var CheckGraphicCode =(rule,value, callback)=>{
            if(!value){
                return callback(new Error('图形码不能为空'));
            }
            setTimeout(() => {
                if(value != this.NewGraphicCode ){
                    return callback(new Error('图形码错误!'))
                }
            }, 800);
            
        }
        var CheckVerificationCode=(rule,value, callback)=>{
            if(!value){
                return callback(new Error('验证码不能为空'));
            }
           
        }
        return{
            src: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            QRisShow:false,
            SigninForm:{
                UserName:'',
                phone:'',
                GraphicCode:'',   //图形码
                VerificationCode:'',     //验证码
                NewPassword:'',    //新密码     
            },
            NewGraphicCode:'',
            rules:{
                    UserName:[
                        { required: true, message: '账号不能为空', trigger: 'blur' },
                    ],
                    phone:[
                        { required: true, message: '手机号不能为空', trigger: 'blur' },
                        { min: 11,max: 11, message: '手机号格式错误', trigger: 'blur' }
                    ],
                    GraphicCode:[
                        { validator: CheckGraphicCode, trigger: 'blur' }
                    ],
                    VerificationCode:[
                        { validator: CheckVerificationCode, trigger: 'blur' }
                    ],
                    NewPassword:[
                        { required: true, message: '新密码不能为空', trigger: 'blur' },
                        { min: 6, message: '新密码长度不得低于6个字符', trigger: 'blur' }
                    ]
                }
        }
    },
    methods:{
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
            if (valid) {
                alert('submit!');
            } else {
                console.log('error submit!!');
                return false;
            }
            });
        },
        

        ObtainCode(code){
            this.NewGraphicCode = code
        },
        // 验证码按钮点击
        CheckVerificationCode(){
            if(this.SigninForm.phone.length != 11){
                this.$message({
                    message: '手机号格式不正确',
                    type: 'error'
                });
            }
        },
        refresh(){
            this.$refs.GraphicCode.refresh()
        },
        goLogin(){
            this.$router.push(
                {
                    name:'login'
                }
            )
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/views/login/ForgotPassword/ForgotPassword.scss"
</style>