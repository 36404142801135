import config from "@/config"
import http from "@/utils/request"
// 巡检记录
export default{
    add:{
		url: `${config.API_URL}/inspectionRecord/`,
		name: "新增巡检记录",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    list:{
		url: `${config.API_URL}/inspectionRecord/`,
		name: "查询巡检记录",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
}