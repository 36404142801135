import config from "@/config"
import http from "@/utils/request"
// 任务类型
export default{
    add:{
		url: `${config.API_URL}/taskType/`,
		name: "新增任务类型",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    list:{
		url: `${config.API_URL}/taskType/`,
		name: "查询任务类型",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
    update:{
		url: `${config.API_URL}/taskType/`,
		name: "修改任务类型",
		patch: async function(id,data){
			return await http.patch(this.url+id+'/', data);
		}
	},
	delete:{
		url: `${config.API_URL}/taskType/`,
		name: "删除任务类型",
		delete: async function(id,data){
			return await http.delete(this.url+id+"/",data);
		}
	},
}