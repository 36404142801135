import config from "@/config"
import http from "@/utils/request"


export default {
    getSmartBadgeBasicDataAPI: {
        url: `${config.API_URL}/smartBadge/basic/`,
        name: '查询"智能胸牌"基本数据',
        get: async function (deviceId){
            return await http.get(this.url + deviceId);
        }
    },
    getSmartBadgePhoneAPI: {
        url: `${config.API_URL}/smartBadge/phone/`,
        name: '查询"智能胸牌"号码',
        get: async function(deviceId) {
            return await http.get( this.url + deviceId );
        }
    },
    getSmartBadgeWifiScanAPI: {
        url: `${config.API_URL}/smartBadge/wifiScan/`,
        name: "查询智能胸牌WIFIScan",
        get: async function(deviceId) {
            return await http.get(this.url + deviceId);
        }
    },
    getSmartBadgeClassRoomAPI: {
        url: `${config.API_URL}/smartBadge/classroom/`,
        name: "查询智能胸牌-课堂模式",
        get: async function(deviceId) {
            return await http.get( this.url + deviceId );
        }
    },
    getSmartBadgeAlarmClockAPI: {
        url: `${config.API_URL}/smartBadge/alarmClock/`,
        name: "查询智能胸牌-闹钟",
        get: async function(deviceId) {
            return await http.get( this.url + deviceId );
        }
    },
    getSmartBadgeCallLogsAPI: {
        url: `${config.API_URL}/smartBadge/callLogs/`,
        name: "查询智能胸牌通话记录",
        get: async function(deviceId, data) {
            return await http.get( this.url + deviceId, data );
        }
    },
    getSmartBadgeLbsAPI: {
        url: `${config.API_URL}/smartBadge/lbs/`,
        name: "查询智能胸牌-lbs",
        get: async function(deviceId, data) {
            return await http.get( this.url + deviceId, data );
        }
    },
    getSmartBadgeStepCountDataAPI: {
        url: `${config.API_URL}/smartBadge/stepCountData/`,
        name: "查询智能胸牌计步记录'step_count_data'记录",
        get: async function(deviceId, data) {
            return await http.get( this.url + deviceId, data );
        }
    },
    getSmartBadgeGnssAPI: {
        url: `${config.API_URL}/smartBadge/gnss/`,
        name: "查询智能胸牌'gnss'记录",
        get: async function(deviceId, data) {
            return await http.get( this.url + deviceId, data );
        }
    },
    getSmartBadgeGnssFenceAPI: {
        url: `${config.API_URL}/smartBadge/gnssFence/`,
        name: "查询智能胸牌'gnss_fence'记录",
        get: async function(deviceId, data) {
            return await http.get( this.url + deviceId, data );
        }
    },
    getRailNoteAPI: {
        url: `${config.API_URL}/smartBadge/order/1G/00/`,
        name: "查询电子围栏模式",
        get: async function(deviceId) {
            return await http.get( this.url + deviceId );
        }
    },
    getVoiceNoteAPI: {
        url: `${config.API_URL}/smartBadge/order/0Z/01/`,
        name: "设置语音短信播放",
        get: async function(deviceId) {
            return await http.get( this.url + deviceId );
        }
    },

    // -------------------------------------------功能下发-------------------------------------------
    setHeartbeatTimeAPI: {
        url: `${config.API_URL}/smartBadge/order/01/01/`,
        name: "设置心跳时间",
        post: async function(deviceId, data) {
            return await http.post(this.url + deviceId, data)
        }
    },

    setKinshipPhoneAPI: {
        url: `${config.API_URL}/smartBadge/order/0D/01/`,
        name: "设置亲情号",
        post: async function(deviceId, data) {
            return await http.post(this.url + deviceId, data)
        }
    },

    setElectricQuantityAPI: {
        url: `${config.API_URL}/smartBadge/order/0W/01/`,
        name: "设置电量",
        post: async function(deviceId, data) {
            return await http.post(this.url + deviceId, data)
        }
    },

    setClassroomPatternAPI: {
        url: `${config.API_URL}/smartBadge/order/0X/01/`,
        name: "设置课堂模式",
        post: async function(deviceId, data) {
            return await http.post(this.url + deviceId, data)
        }
    },

    setVolumeAPI: {
        url: `${config.API_URL}/smartBadge/order/0V/01/`,
        name: "设置音量大小",
        post: async function(deviceId, data) {
            return await http.post(this.url + deviceId, data)
        }
    },

    setMuteOffAPI: {
        url: `${config.API_URL}/smartBadge/order/12/01/`,
        name: "设置静音模式",
        post: async function(deviceId, data) {
            return await http.post(this.url + deviceId, data)
        }
    },

    setAudioCalibrationAPI: {
        url: `${config.API_URL}/smartBadge/order/1A/01/`,
        name: "查询音频校准",
        post: async function(deviceId) {
            return await http.post(this.url + deviceId)
        }
    },

    setVoiceNotePlayAPI: {
        url: `${config.API_URL}/smartBadge/order/0Z/01/`,
        name: "设置语音短信播放",
        post: async function(deviceId, data) {
            return await http.post(this.url + deviceId, data)
        }
    },

    setStepSensitivityAPI: {
        url: `${config.API_URL}/smartBadge/order/1S/01/`,
        name: "设置计步灵敏度",
        post: async function(deviceId, data) {
            return await http.post(this.url + deviceId, data)
        }
    },

    setAlarmClockAPI: {
        url: `${config.API_URL}/smartBadge/order/0Y/01/`,
        name: "设置闹钟",
        post: async function(deviceId, data) {
            return await http.post(this.url + deviceId, data)
        }
    },

    setAlarmBellAPI: {
        url: `${config.API_URL}/smartBadge/order/1Y/01/`,
        name: "设置闹钟名称-铃声",
        post: async function(deviceId, data) {
            return await http.post(this.url + deviceId, data)
        }
    },

    setRailNoteAPI: {
        url: `${config.API_URL}/smartBadge/order/1G/01/`,
        name: "设置电子围栏模式",
        post: async function(deviceId, data) {
            return await http.post(this.url + deviceId, data)
        }
    },

    setheartbeatLocationAPI: {
        url: `${config.API_URL}/smartBadge/order/14/01/`,
        name: "设置心跳定位优先级",
        post: async function(deviceId, data) {
            return await http.post(this.url + deviceId, data)
        }
    },

    initSetting: {
        url: `${config.API_URL}/smartBadge/order/1E/01/`,
        name: "初始化出厂设置",
        post: async function(deviceId) {
            return await http.post(this.url + deviceId)
        }
    },

    locationData: {
        url: `${config.API_URL}/smartBadge/order/0E/00/`,
        name: "下发定位数据指令",
        get: async function(deviceId) {
            return await http.get(this.url + deviceId)
        }
    },

    playBell: {
        url: `${config.API_URL}/smartBadge/order/11/00/`,
        name: "下发铃声播放指令",
        get: async function(deviceId) {
            return await http.get(this.url + deviceId)
        }
    },
}
