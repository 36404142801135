import config from "@/config"
import http from "@/utils/request"

export default {
    list: {
		url: `${config.API_URL}/eventTemplate/`,
		name: "查询通知模板列表",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
    modelList:{
		url: `${config.API_URL}/eventTemplate/`,
		name: "查询通知模板关联的型号列表",
		get: async function(id,data){
			return await http.get(this.url+id+"/model/", data);
		}
	},
	add: {
		url: `${config.API_URL}/eventTemplate/`,
		name: "添加通知模板",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    modelAdd:{
        url: `${config.API_URL}/eventTemplate/model/`,
		name: "新增通知模板关联的型号",
		post: async function(data){
			return await http.post(this.url, data);
		}
    },
    update: {
		url: `${config.API_URL}/eventTemplate/`,
		name: "修改通知模板",
		patch: async function(id,data){
			return await http.patch(this.url+id+"/", data);
		}
	},
    delete: {
		url: `${config.API_URL}/eventTemplate/`,
		name: "删除事件联动规则",
		delete: async function(id,data){
			return await http.delete(this.url+id+"/", data);
		}
	},
	modelDelete:{
		url: `${config.API_URL}/eventTemplate/model/`,
		name: "删除通知模板关联的型号",
		delete: async function(data){
			return await http.delete(this.url, data);
		}
	},
}
