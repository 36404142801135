import config from "@/config"
import http from "@/utils/request"

export default{
    cameraList:{
        url:`${config.API_URL}/query/Camera`,
        name:'摄像头列表',
        get: async function(data){
            return await http.get(this.url,data)
        }
    }

    
}