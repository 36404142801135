import config from "@/config"
import http from "@/utils/request"

export default {
	add: {
		url: `${config.API_URL}/deviceProfile/`,
		name: "添加设备模型",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    delete: {
		url: `${config.API_URL}/deviceProfile/`,
		name: "删除设备模型",
		delete: async function(id,data){
			return await http.delete(this.url+id+"/", data);
		}
	},
    detail: {
		url: `${config.API_URL}/deviceProfile/`,
		name: "查询设备模型详情",
		get: async function(id,data){
			return await http.get(this.url+id+"/", data);
		}
	},
    list: {
		url: `${config.API_URL}/deviceProfile/`,
		name: "查询设备模型列表",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
    update: {
		url: `${config.API_URL}/deviceProfile/`,
		name: "修改设备模型",
		patch: async function(id,data){
			return await http.patch(this.url+id+"/", data);
		}
	},
	select:{
		url: `${config.API_URL}/searchDeviceProfile/`,
		name: "下拉选择设备模型",
		get: async function(data){
			if(data!=null&&data!=""){
				return await http.get(this.url+data+"/");
			}else{
				return await http.get(this.url);
			}
		}
	},
	batchDelete:{
		url: `${config.API_URL}/d/`,
		name: "批量删除",
		patch: async function(id,data){
			return await http.delete(this.url+id+"/", data);
		}
	},
	updatePlatform:{
		url: `${config.API_URL}/updateProfilePlatform/`,
		name: "修改设备模型的运营商平台",
		post: async function(data){
			return await http.post(this.url, data);
		}
	}
}
