import config from "@/config"
import http from "@/utils/request"

export default {
    select: {
		url: `${config.API_URL}/iotPlatform/`,
		name: "查询iot平台",
		get: async function(){
			return await http.get(this.url, null);
		}
	},
}
