
const time ={
    now: new Date(new Date().toISOString().slice(0,10)),
    moreTime:[],
    // 当前月份
    mother:function(){
        return this.now.getMonth()+1
    },
    AllTime:function(num){
        this.moreTime = []
        for(let i= 0;i<num;i++){
            this.moreTime.unshift(new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate()+1 -i).toISOString().slice(0, 10))
          }
    },
    // 所需的日期
    getTime: function(num){
        this.AllTime(num)
        return this.moreTime
    },
}
export default time