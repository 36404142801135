import { render, staticRenderFns } from "./newLogin.vue?vue&type=template&id=3d84da1e&scoped=true"
import script from "./newLogin.vue?vue&type=script&lang=js"
export * from "./newLogin.vue?vue&type=script&lang=js"
import style0 from "./newLogin.vue?vue&type=style&index=0&id=3d84da1e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d84da1e",
  null
  
)

export default component.exports