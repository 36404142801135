import config from "@/config"
import http from "@/utils/request"

export default {
    // 查询参数
    getGb28181JumpAPI: {
        url: `${config.API_URL}/GB28181/`,
        name: '查询GB28181详细参数',
        get: async function(deviceNumber) {
            return await http.get( this.url + deviceNumber )
        }
    },
    // ----------------------------------------------------单个设置----------------------------------------------------
    // 设置GB28181
    setGbServiceAPI: {
        url: `${config.API_URL}/GB28181/gbServerSetting/`,
        name: '设置GB28181',
        post: async function(deviceId, data) {
            return await http.post( this.url + deviceId, data )
        }
    },
    // http推送
    httpPushAPI: {
        url: `${config.API_URL}/GB28181/alarmPushSetting/`,
        name: 'http推送',
        post: async function(deviceId, data) {
            return await http.post(this.url + deviceId, data)
        }
    },

    // 串口透传
    serialportAPI: {
        url: `${config.API_URL}/GB28181/alarmSetting/serialport/`,
        name: '串口透传',
        post: async function(deviceId, data) {
            return await http.post( this.url + deviceId, data )
        }
    },
    // FTP推送
    ftpPushAPI: {
        url: `${config.API_URL}/GB28181/alarmSetting/ftpPush/`,
        name: 'FTP推送',
        post: async function(deviceId, data) {
            return await http.post( this.url + deviceId, data )
        }
    },
    // Email推送
    emailPushAPI: {
        url: `${config.API_URL}/GB28181/alarmSetting/emailPush/`,
        name: 'Email推送',
        post: async function(deviceId, data) {
            return await http.post( this.url + deviceId, data )
        }
    },
    // MQTT推送
    mqttPushAPI: {
        url: `${config.API_URL}/GB28181/alarmSetting/mqttPush/`,
        name: 'MQTT推送',
        post: async function(deviceId, data) {
            return await http.post( this.url + deviceId, data )
        }
    },
    // 火警参数
    fireAlarmSettingAPI: {
        url: `${config.API_URL}/GB28181/fireAlarmSetting/`,
        name: '火警参数',
        post: async function(deviceId, data) {
            return await http.post(this.url + deviceId, data)
        }
    },
    // 电动车报警参数
    eBikeAlarmSettingAPI: {
        url: `${config.API_URL}/GB28181/eBikeAlarmSetting/`,
        name: '电动车报警参数',
        post: async function(deviceId, data) {
            for (const key in data) {
                data[key] = Number(data[key]);
            }
            return await http.post( this.url + deviceId, data )
        }
    },
    // 视频设置
    videoAPI: {
        url: `${config.API_URL}/GB28181/videoSetting/`,
        name: '视频设置',
        post: async function(deviceId, data) {
            return await http.post( this.url + deviceId, data )
        }
    },
    // 音频设置
    audioAPI: {
        url: `${config.API_URL}/GB28181/audioSetting/`,
        name: '音频设置',
        post: async function(deviceId, data) {
            return await http.post( this.url + deviceId, data )
        }
    },
    // ISP设置
    ispSettingAPI: {
        url: `${config.API_URL}/GB28181/ispSetting/`,
        name: 'ISP设置',
        post: async function(deviceId, data) {
            return await http.post( this.url + deviceId, data )
        }
    },
    // WiFi热点设置
    wifiSettingAPI: {
        url: `${config.API_URL}/GB28181/wifiSetting/`,
        name: 'WiFi热点设置',
        post: async function(deviceId, data) {
            return await http.post( this.url + deviceId, data )
        }
    },
    
    // 区域入侵设置
    regionAPI: {
        url: `${config.API_URL}/GB28181/mediaSetting/feature/region/`,
        name: '区域入侵设置',
        post: async function(deviceId, data) {
            return await http.post( this.url + deviceId, data )
        }
    },
    // 云台控制
    holderAPI: {
        url: `${config.API_URL}/GB28181/mediaSetting/feature/holder/`,
        name: '云台控制',
        post: async function(deviceId, data) {
            return await http.post( this.url + deviceId, data )
        }
    },
    // 继电器/其他设备控制
    relayAPI: {
        url: `${config.API_URL}/GB28181/mediaSetting/feature/relay/`,
        name: '继电器/其他设备控制',
        post: async function(deviceId, data) {
            return await http.post( this.url + deviceId, data )
        }
    },
    // 录像播放
    recordAPI: {
        url: `${config.API_URL}/GB28181/mediaSetting/record/`,
        name: '录像播放',
        post: async function(deviceId, data) {
            return await http.post( this.url + deviceId, data )
        }
    },
    // 录像控制
    recordControlAPI: {
        url: `${config.API_URL}/GB28181/mediaSetting/recordControl/`,
        name: '录像控制',
        post: async function(deviceId, data) {
            return await http.post( this.url + deviceId, data )
        }
    },
    // 查询音频校准
    audioCalibration: {
        url: `${config.API_URL}/smartBadge/order/1A/01/`,
        name: "查询音频校准",
        post: async function(deviceId) {
            return await http.post(this.url + deviceId)
        }
    },


    // ----------------------------------------------------批量设置----------------------------------------------------
    // 批量设置GB28181参数
    gb28181SettingBatchAPI: {
        url: `${config.API_URL}/GB28181/batch/GB28181Setting`,
        name: '批量设置GB28181参数',
        post: async function( data) {
            return await http.post( this.url , data )
        }
    },
    httpPusghSettingBatchAPI: {
        url: `${config.API_URL}/GB28181/batch/alarmSetting/httpPush`,
        name: '批量设置HTTP推送',
        post: async function( data) {
            return await http.post( this.url ,  data )
        }
    },
    serialportSettingBatchAPI: {
        url: `${config.API_URL}/GB28181/batch/alarmSetting/serialport`,
        name: '批量设置串口透传',
        post: async function( data) {
            return await http.post( this.url ,  data )
        }
    },
    ftpPushSettingBatchAPI: {
        url: `${config.API_URL}/GB28181/batch/alarmSetting/ftpPush`,
        name: '批量设置FTP推送',
        post: async function( data) {
            return await http.post( this.url ,  data )
        }
    },
    emailPushSettingBatchAPI: {
        url: `${config.API_URL}/GB28181/batch/alarmSetting/emailPush`,
        name: '批量设置Email推送',
        post: async function( data) {
            return await http.post( this.url ,  data )
        }
    },
    mqttPushSettingBatchAPI: {
        url: `${config.API_URL}/GB28181/batch/alarmSetting/mqttPush`,
        name: '批量设置MQTT推送',
        post: async function( data) {
            return await http.post( this.url ,  data )
        }
    },
    fireAlarmSettingBatchAPI: {
        url: `${config.API_URL}/GB28181/batch/alarmSetting/fireAlarmSetting`,
        name: '批量设置火警参数',
        post: async function( data) {
            return await http.post( this.url ,  data )
        }
    },
    eBikeAlarmSettingBatchAPI: {
        url: `${config.API_URL}/GB28181/batch/alarmSetting/eBikeAlarmSetting`,
        name: '批量设置电动车报警参数',
        post: async function( data) {
            return await http.post( this.url ,  data )
        }
    },
    videoSettingBatchAPI: {
        url: `${config.API_URL}/GB28181/batch/mediaSetting/video`,
        name: '批量视频设置',
        post: async function( data) {
            return await http.post( this.url ,  data )
        }
    },
    audioSettingBatchAPI: {
        url: `${config.API_URL}/GB28181/batch/mediaSetting/audio`,
        name: '批量音频设置',
        post: async function( data) {
            return await http.post( this.url ,  data )
        }
    },
    ispSettingBatchAPI: {
        url: `${config.API_URL}/GB28181/batch/mediaSetting/ispSetting`,
        name: '批量设置ISP',
        post: async function( data) {
            return await http.post( this.url ,  data )
        }
    },
    wifiSettingBatchAPI: {
        url: `${config.API_URL}/GB28181/batch/mediaSetting/wifiSetting`,
        name: '批量WiFi热点设置',
        post: async function( data) {
            return await http.post( this.url ,  data )
        }
    },

    // ----------------------------------------------------视频和对讲----------------------------------------------------
    playGB28181Live: {
        url: `${config.API_URL}/GB28181/videoStreaming/`,
        name: "GB28181视频播放",
        post: async function( deviceId, data ) {
            return await http.post( this.url + deviceId, data )
        }
    },
    gb28181Intercom: {
        url: `${config.API_URL}/GB28181/mediaSetting/intercom/`,
        name: "对讲",
        post: async function( deviceId, data ) {
            return await http.post( this.url + deviceId, data )
        }
    },
    gb28181Broadcast: {
        url: `${config.API_URL}/GB28181/broadcast/`,
        name: "广播",
        post: async function(deviceId, data) {
            return await http.post(this.url + deviceId, data)
        }
    },

    playBroadcast: {
        url: `${config.API_URL}/GB28181/broadcast/play/`,
        name: "GB28181播放广播",
        post: async function( deviceNumber, data ) {
            return await http.post( this.url + deviceNumber, data )
        }
    },

    // ----------------------------------------------------录像回访----------------------------------------------------
    gb28181Record: {
        url: `${config.API_URL}/GB28181/mediaSetting/record/`,
        name: "录像播放",
        post: async function( deviceId, data ) {
            return await http.post( this.url + deviceId, data )
        }
    },
    
    gb28181RecordControl: {
        url: `${config.API_URL}/GB28181/mediaSetting/recordControl/`,
        name: "录像控制",
        post: async function( deviceId, data ) {
            return await http.post( this.url + deviceId, data )
        }
    },
    
    // ---------------------------------------------------区域入侵设置---------------------------------------------------
    gb28181Region: {
        url: `${config.API_URL}/GB28181/mediaSetting/feature/region/`,
        name: "区域入侵设置",
        post: async function (deviceId, data) {
            return await http.post( this.url + deviceId, data ); 
        }
    },
   
    gb28181Relay: {
        url: `${config.API_URL}/GB28181/mediaSetting/feature/relay/`,
        name: "继电器/其他设备控制",
        post: async function (deviceId, data){
            return await http.post(this.url + deviceId, data);
        }
    },

    recordList: {
        url: `${config.API_URL}/GB28181/recordList/`,
        name: "GB28181查询录像列表",
        post: async function (id, data) {
            return await http.post(this.url + id, data, "application/json");
        }   
    },

    perimeter: {
        url: `${config.API_URL}/GB28181/mediaSetting/feature/region/`,
        name: "周界",
        post: async function (id, data) {
            return await http.post(this.url + id, data);
        }
    },

    // ---------------------------------------------------新增设置---------------------------------------------------
    // 电动车识别禁止线设置
    restrictedLine: {
        url: `${config.API_URL}/GB28181/restrictedLineSetting/`,
        name: "电动车识别禁止线设置",
        post: async function (id, data) {
            return await http.post(this.url + id, data);
        }
    },
    // 火焰识别周界设置
    regionSetting: {
        url: `${config.API_URL}/GB28181/regionSetting/`,
        name: "火焰识别周界设置",
        post: async function (id, data) {
            return await http.post(this.url + id, data);
        }
    },
    // UDP服务设置
    udpSetting: {
        url: `${config.API_URL}/GB28181/udpSetting/`,
        name: "UDP服务设置",
        post: async function (id, data) {
            return await http.post(this.url + id, data);
        }
    },
    // 恢复默认参数
    paramDefault: {
        url: `${config.API_URL}/GB28181/paramDefaultSetting/`,
        name: "恢复默认参数",
        post: async function (id, data) {
            return await http.post(this.url + id, data);
        }
    },
    // 辅助控制
    subplateCtrl: {
        url: `${config.API_URL}/GB28181/subplateCtrl/`,
        name: "辅助控制",
        post: async function (id, data) {
            return await http.post(this.url + id, data);
        }
    },
    // 云台控制
    ptzCtr: {
        url: `${config.API_URL}/GB28181/ptzCtrl/`,
        name: "云台控制",
        post: async function (id, data) {
            return await http.post(this.url + id, data);
        }
    },

    // 重启系统
    rebootSystem: {
        url: `${config.API_URL}/GB28181/rebootSystem/`,
        name: "重启系统",
        post: async function (id) {
            return await http.post(this.url + id);
        }
    },
}