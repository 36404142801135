import config from "@/config"
import http from "@/utils/request"

export default {
	add: {
		url: `${config.API_URL}/deviceType/`,
		name: "添加设备类型",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    delete: {
		url: `${config.API_URL}/deviceType/`,
		name: "删除设备类型",
		delete: async function(id,data){
			return await http.delete(this.url+id+"/", data);
		}
	},
    list: {
		url: `${config.API_URL}/deviceType/`,
		name: "查询设备类型列表",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
    update: {
		url: `${config.API_URL}/deviceType/`,
		name: "修改设备类型",
		patch: async function(id,data){
			return await http.patch(this.url+id+"/", data);
		}
	},
	select:{
		url: `${config.API_URL}/searchDeviceType/`,
		name: "下拉搜索设备类型",
		get: async function(data,newData){
			if(data!=null&&data!=""){
				return await http.get(this.url+data+"/",newData);
			}else{
				return await http.get(this.url,newData);
			}
		}
	}
}
