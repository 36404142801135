import config from "@/config"
import http from "@/utils/request"

export default {
	add: {
		url: `${config.API_URL}/pointCollection/`,
		name: "添加安装点收藏",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    delete: {
		url: `${config.API_URL}/pointCollection/`,
		name: "删除安装点收藏",
		delete: async function(data){
			return await http.delete(this.url, data);
		}
	},
    list: {
		url: `${config.API_URL}/pointCollection/`,
		name: "查询安装点收藏列表",
		get: async function(data){
			return await http.get(this.url, data);
		}
	}
}
