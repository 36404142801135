import config from "@/config"
import http from "@/utils/request"

export default {
	add: {
		url: `${config.API_URL}/menu/`,
		name: "添加菜单",
		post: async function(data){
			return await http.post(this.url, data);
		}
	},
    delete: {
		url: `${config.API_URL}/menu/`,
		name: "删除菜单",
		delete: async function(id,data){
			return await http.delete(this.url+id+"/", data);
		}
	},
    list: {
		url: `${config.API_URL}/menu/`,
		name: "查询菜单列表",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},
    update: {
		url: `${config.API_URL}/menu/`,
		name: "修改菜单",
		patch: async function(id,data){
			return await http.patch(this.url+id+"/", data);
		}
	},
	all:{
		url: `${config.API_URL}/allMenu/`,
		name: "查询所有菜单列表",
		get: async function(data){
			return await http.get(this.url, data);
		}
	}
}
