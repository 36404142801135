import config from "@/config"
import http from "@/utils/request"

export default {
    queryLabelType: {
        url: `${config.API_URL}/queryLabelType/`,   
        name: "查询标签类型",
        get: async function (data) {
            return await http.get(this.url, data);
        }
    },

    addLabelType: {
        url: `${config.API_URL}/addLabelType/`,
        name: "添加标签类型",
        post: async function(data) {
            return await http.post( this.url, data );
        }
    },

    updateLabelType: {
        url: `${config.API_URL}/updateLabelType/`, 
        name: "修改标签类型",
        put: async function (data, id) {
            return await http.patch( this.url + id, data );
        }
    },

    deleteLabelType: {
        url: `${config.API_URL}/deleteLabelType/`,
        name: "删除标签",
        delete: async function (id) {
            return await http.delete( this.url + id );
        }
    },
    
    getAllCameraEvent: {
        url: `${config.API_URL}/getAllCameraEvent/`,
        name: "查询账号下所有摄像头事件记录",
        get: async function (data) {
            return await http.get(this.url, data);
        }
    },

    updateCameraEventLabel: {
        url: `${config.API_URL}/updateCameraEventLabel`,
        name: "修改图片标签",
        post: async function (data) {
            return await http.post( this.url, data );
        }
    }
}