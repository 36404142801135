import { Message } from 'element-ui';

export default {
    // 警告
    warningOpen: (msg)=>{
        return Message({
            showClose: true,
            message:msg,
            type: "warning",
            center: true,
            duration:3000
         })
    },
    //成功
    successOpen:(msg)=>{
        return Message({
            showClose:true,
            message:msg,
            type: "success",
            center: true,
            duration:3000
        })
    },
    //普通
    messageOpen:(msg)=>{
        return Message({
            showClose:true,
            message:msg,
            center: true,
            duration:3000
        })
    },
    //错误
    errorOpen:(msg)=>{
        return Message({
            showClose:true,
            message:msg,
            type: "error",
            duration:3000,
            center: true,
        })
    }

}