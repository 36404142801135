import config from "@/config"
import http from "@/utils/request"

export default {
    update: {
		url: `${config.API_URL}/modelStatus/`,
		name: "修改型号状态次数",
		patch: async function(id,data){
			return await http.patch(this.url+id+"/", data);
		}
	},
}
