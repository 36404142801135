const xiXiangGas = {
    state:{
        asideWidth:'250px',
        isCollapse:false,
        contanName:'homePage',
        isHome:true,
    },
    mutations:{
        changeWidth(state){
            // state.asideWidth ==='50px' ? state.asideWidth ='200px' :state.asideWidth='50px'
            state.isCollapse ? state.isCollapse = false : state.isCollapse =true
        },
        changeContanName(state,val){
            state.contanName = val
        },
        changeXiXiangHome(state){
            state.isHome = !state.isHome
        }
    },
    actions:{

    },
    getter:{

    }
}

export default xiXiangGas