const children = {
    state:{
        pitchDevice:{},
        isFullScreen:false,
        
    },
    mutations:{
        // 设备信息
        pitch(state,deviceNews){
            state.pitchDevice = deviceNews
        },
        changeFullScreen(state,fla){
            state.isFullScreen = fla
        }
    },
    actions:{

    },
    getter:{

    }
}

export default children